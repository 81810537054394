.DeklarataDheTestimi {
  display: flex;
  align-items: center;
  padding: 0 8%;

  p {
    margin: 0;
  }

  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: max(30px, 1.5vw);

    span {
      text-transform: uppercase;
      opacity: 0.5;
    }

    p {
      line-height: max(30px, 1.5vw);
      text-wrap: balance;
    }

    .Btn {
      &::before {
        display: none;
      }
    }

    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .right {
    width: 50%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    .deklarata__image {
      width: 80%;
      aspect-ratio: 1/0.54;
      border-radius: max(11px, 0.55vw);
      background-image: url("../../../assets/DeklarataDheTestimi/bgDeklarata.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-color: var(--light-color);
        opacity: 0.05;
        position: absolute;
        top: max(-15px, -0.75vw);
        left: max(-15px, -0.75vw);
        border-radius: max(30px, 1.5vw);
        z-index: -1;
      }
    }

    .deklarata__circle__1 {
      // width: max(300px, 24vw);
      // height: max(300px, 24vw);
      width: 50%;
      aspect-ratio: 1/1;
      border-radius: 100% !important;
      border: 1px dashed var(--main-color);
      opacity: 0.4;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      transform: translate(60%, -25%);
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &::before {
        content: "";
        background-color: #272727;
        // width: max(28px, 1.4vw);
        // height: max(28px, 1.4vw);
        width: 7.5%;
        aspect-ratio: 1/1 !important;
        border-radius: 100% !important;
        position: absolute;
        top: 10%;
        right: 12.5%;
      }
    }

    .deklarata__circle__2 {
      // width: max(30px, 1.5vw);
      // height: max(30px, 1.5vw);
      width: 4%;
      background-color: var(--main-color);
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      position: absolute;
      left: 10%;
      bottom: 20%;
    }

    .deklarata__circle__3 {
      width: 70%;
      aspect-ratio: 1/1;
      background-image: url("../../../assets/DeklarataDheTestimi/shade.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      position: absolute;
      right: 0;
      top: 0;
      transform: translate(7.5%, -10%);
      z-index: -1;
    }

    .deklarata__circle__4 {
      // width: max(30px, 1.5vw);
      // height: max(30px, 1.5vw);
      width: 4%;
      background-color: var(--light-color);
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      position: absolute;
      right: 15%;
      bottom: -30%;
    }

    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;

      .deklarata__circle__2 {
        left: 0%;
      }
    }
  }

  @media (min-width: 900px) {
    margin: max(150px, 7.5vw) 0;
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    gap: max(100px, 5vw);
    width: 100%;
  }
}

.Navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2% 4%;
    height: max(50px, 2.5vw);
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw) max(10px, 0.5vw);

    .Navbar__1 {
        width: max(144px, 12vw);
        height: max(30px, 2.3vw);
        content: url('../../../assets/shared/fullLogo.svg');
        cursor: pointer;
    }

    .Navbar__2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: max(12px, 2vw);
        height: 100%;

        .navbarItems {
            height: max(50px, 2.5vw);

            position: relative;
            a {
                text-decoration: none;
                color: #2B2B2B;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: max(2px, 0.1vw);
                height: 100%;
                background-color: #fff;
            }
        }
        
    }

    .Navbar__3 {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: max(12px, 1vw);
        height: 100%;

        .navbar__search {
            width: max(69px, 5.75vw);
            height: max(30px, 1.95vw);
            display: flex;
            align-items: center;
            border-bottom: 1.5px solid #2B2B2B;
            position: relative;

            svg {
                width: max(18px, 0.9vw);
                height: max(18px, 0.9vw);
                color: #2B2B2B;
                position: absolute;
                z-index: 2;
            }

            input {
                width: 100%;
                height: 100%;
                position: absolute;
                padding-left: max(30px, 1.5vw);
                outline: none;
                border: none;
            }
        }

        a {
            text-decoration: none !important;
            color: #2B2B2B;
        }

        .kycu {
            width: max(80px, 5.55vw);
            height: max(30px, 1.95vw);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100vmax !important;
            border: 1px solid #2B2B2B;
        }

        .regjistrohu {
            width: max(90px, 6.4vw);
            height: max(30px, 1.95vw);
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100vmax !important;
            background-color: var(--main-color);
            color: #fff;
        }
    }

    .mobileNavbar {
        display: none;
    }

    @media (max-width: 1024px) {
        .Navbar__1,
        .Navbar__2,
        .Navbar__3 {
            display: none;
        }
        padding: 2% 0;
        position: relative;


        .mobileNavbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: max(50px, 2.5vw);
            position: fixed;
            background-color: #fff;
            z-index: 8;


            .mobileLogo {
                position: relative;
                z-index: 2;
                width: max(150px, 7.5vw);
                // content: url('../../../assets/shared/fullLogo.svg');
                height: max(50px, 2.5vw);
                width: max(300px, 15vw);
                background-image: url('../../../assets/shared/fullLogo.svg');
                background-position: left max(20px, 1vw) center;
                background-repeat: no-repeat;
                background-size: max(200px, 10vw);
                background-color: #fff;
            }

            .mobileMenu {
                position: relative;
                z-index: 2;
                margin-right: max(20px, 1vw);

                
                svg {
                    width: max(30px, 1.5vw);
                    height: max(30px, 1.5vw);
                    cursor: pointer;
                }

                .makeSvgWhite {
                    color: #fff;

                    @media (max-width: 400px) {
                        color: #000;
                    }
                }
            }
        }

        .closeMenu {
            display: none;
        }

        .openMenu {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            // width: 100vw;
            width: max(300px, 15vw);
            height: 100vh;
            box-shadow: 0vw 0 100vw 100vw rgba(0, 0, 0, .7);
        }
    }

    .openNavmenu {
        padding: max(20px, 1vw);
        border-radius: max(15px, 0.75vw);
        box-shadow: 0 0 max(10px, 0.5vw) 0px rgba(0, 0, 0, .1);
        background-color: #fff;
        display: grid;
        align-items: flex-start;
        justify-content: flex-start;
        grid-template-columns: repeat(2, 1fr);
        row-gap: max(30px, 1vw);
        column-gap: max(80px, 4vw);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: max-content !important;
        display: none !important;

    }


    .rrethNesh {
        &:hover {
            .openNavmenu {
                display: grid !important;
            }
        }
    }

  


    .openNavmenu2 {
        display: flex;
        flex-direction: row!important;
        align-items: center;
        grid-template-columns: repeat(1, 1fr);

        display: none;
        transform: translateX(-38%);

        .leftOpenNavMenu {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: max(20px, 1vw);

            .leftOpenNavMenu__inside {
                display: grid;
                align-items: flex-start;
                justify-content: flex-start;
                grid-template-columns: repeat(2, 1fr);
                row-gap: max(30px, 1vw);
                column-gap: max(80px, 4vw);
            }
        }

        .rightNavMenu {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: max(30px, 1.5vw);

            .sherbimetIcon {
                width: max(110px, 5.5vw);
                content: url('../../../assets/Sherbimet/sherbimetTestkos.svg');
            }

            .rightNavMenu__texts{
                display: flex;
                flex-direction: column;
                gap: max(15px, 0.75vw);
            }

            .rightNavMenu__inside{
                display: flex;
                flex-direction: column;
                gap: max(5px, 0.25vw);

                p {
                    margin: 0;
                }
            }


        }


    }

    .changeColorToMainColor {
        display: flex;
        align-items: center;
        gap: max(5px, 0.25vw);
        color: var(--main-color) !important;
    }

    .sherbimet {
        &:hover {
            .openNavmenu2 {
                display: flex !important;
            }
        }
    }


    .kontakto{
        font-size: max(13px, 0.65vw) !important;
        font-family: sans-serif !important;
        color: #fff !important;
        width: max(120px, 6vw) !important;
        height: max(31px, 1.75vw) !important;
        border-radius: max(6px, 0.3vw) !important;
        background: var(--main-color) !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
}

body.dark-mode {
    .Navbar {
        background-color: #2B2B2B;

        .Navbar__1{
            filter: brightness(10000%);
        }

        a,p {
            color: #fff !important;

        }

        .kycu {
            color: #fff;
            border-color: #fff;
        }

        .navbar__search {
            background-color: #2B2B2B;
            border-color: #fff;

            svg {
                color: #fff;
            }

            input {
                background-color: #2B2B2B;
                color: #fff;
            }
        }

        .navbarItems {
            background-color: #2B2B2B ;
            a {
                background-color: #2B2B2B;
            }
        }

        .openNavmenu{
            background-color: #2B2B2B;
        }
    }

    .mobileNavbar {
        background-color: #2B2B2B !important;
        .mobileLogo {
            background-color: #2B2B2B;
            background-image: url('../../../assets/shared/darkLogo.svg');
        }
    }

   
    .makeSvgWhite {
        color: #fff;

        @media (max-width: 400px) {
            color: #fff !important;
        }
    }
}


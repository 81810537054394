.Home {
    position: relative;
    top: 4%;
    width: 100%;

    .test__space {
        color: gray;
        width: 100%;
        height: 200vh;
    }

    @media (min-width: 1025px) {
        margin-top: 4%;
    }
    
}


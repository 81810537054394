.App {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

@font-face {
    font-family: 'mo-medium';
    src: url('./fonts/Montserrat-Medium.woff');
}

@font-face {
    font-family: 'mo-regular';
    src: url('./fonts/Montserrat-Regular.woff');
}

@font-face {
    font-family: 'mo-bold';
    src: url('./fonts/Montserrat-Bold.woff');
}

@font-face {
    font-family: 'mo-semibold';
    src: url('./fonts/Montserrat-SemiBold.woff');
}

@font-face {
    font-family: 'nn-regular';
    src: url('./fonts/NunitoSans_7pt_Condensed-Regular.woff');
}

@font-face {
    font-family: 'nn-medium';
    src: url('./fonts/NunitoSans_7pt_Condensed-Medium.woff');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root{
    --main-color:#4E940F;
    --main-color-rgb:33, 146, 104;
    --dark-color: #07271c;
    --shadow:#1D875F;
    --light-color:#00C193;
    --darker-color: #084730;
    --darker-color-rgb: 8, 71, 48;
    --other-color: #103123;
}

body.theme-green {
    --main-color:#4E940F;
    --main-color-rgb:33, 146, 104;
    --dark-color: #07271c;
    --shadow:#1D875F;
    --light-color:#00C193;
    --darker-color: #084730;
    --darker-color-rgb: 8, 71, 48;
    --other-color: #103123;
}
  
body.theme-blue {
    --main-color:#1B74EE;
    --main-color-rgb:27, 116, 238;
    --dark-color: #082041;
    --shadow:#145AB8;
    --light-color:#4F9AFF;
    --darker-color: #133a70;
    --darker-color-rgb: 19, 58, 112;
    --other-color: #0b254b;
}

body.theme-red {
    --main-color:#C11B36;
    --main-color-rgb:193, 27, 54;
    --dark-color: #3a050f;
    --shadow:#8B1125;
    --light-color:#DB777E;
    --darker-color: #591A28;
    --darker-color-rgb: 89, 26, 40;
    --other-color: #4A0D12;
}

body.theme-orange {
    --main-color:#FF9C39;
    --main-color-rgb:128, 53, 160;
    --dark-color: #312110;
    --shadow:#e2ae7a;
    --light-color:#ffac58;
    --darker-color: #5c3811;
    --darker-color-rgb: 72, 0, 90;
    --other-color: #68523d;
}

.regular14 {
    font-size: max(11.2px, 0.7vw);
    font-family: 'mo-regular';
}

.regular15 {
    font-size: max(12px, 0.75vw);
    font-family: 'mo-regular';
}

.regular16 {
    font-size: max(12.8px, 0.8vw) !important;
    font-family: 'mo-regular' !important;
}

.regular17 {
    font-size: max(13.2px, 0.85vw) !important;
    font-family: 'mo-regular' !important;
}

.regular18 {
    font-size: max(14.4, 0.9vw);
    font-family: 'mo-regular';
}

.regular19 {
    font-size: max(15.2px, 0.95vw);
    font-family: 'mo-regular';
}

.regular20 {
    font-size: max(16px, 1vw);
    font-family: 'mo-regular';
}

.regular40 {
    font-size: max(32px, 2vw);
    font-family: 'mo-regular';
}

.regular43 {
    font-size: max(34.4, 2.15vw);
    font-family: 'mo-regular';
}

.medium15 {
    font-size: max(12px, 0.75vw);
    font-family: 'mo-medium';
}

.medium16 {
    font-size: max(12.8px, 0.8vw) !important;
    font-family: 'mo-medium';
}

.medium17 {
    font-size: max(13.2px, 0.85vw) !important;
    font-family: 'mo-medium';
}

.medium18 {
    font-size: max(14px, 0.9vw) !important;
    font-family: 'mo-medium';
}

.medium19 {
    font-size: max(14.5px, 0.95vw) !important;
    font-family: 'mo-medium';
}

.medium20 {
    font-size: max(16px, 1vw);
    font-family: 'mo-medium';
}

.medium25 {
    font-size: max(20px, 1.25vw) !important;
    font-family: 'mo-medium';
}

.medium32 {
    font-size: max(25.6px, 1.6vw);
    font-family: 'mo-medium';
}

.medium34 {
    font-size: max(26.8px, 1.7vw);
    font-family: 'mo-medium';
}

.medium36 {
    font-size: max(28.8px, 1.8vw);
    font-family: 'mo-medium';
}

.medium40 {
    font-size: max(32px, 2vw);
    font-family: 'mo-medium';
}

.medium43 {
    font-size: max(34.6px, 2.15vw);
    font-family: 'mo-medium';
}

.medium44 {
    font-size: max(35.2px, 2.2vw);
    font-family: 'mo-medium';
}

.bold30 {
    font-size: max(24px, 1.5vw);
    font-family: 'mo-bold';
}

.semibold40 {
    font-size: max(32px, 2vw);
    font-family: 'mo-semibold';
}


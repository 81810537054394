.Footer {
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .Footer__1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: max(20px, 1vw);
    width: 100%;
    aspect-ratio: 1/ 0.2;
    background: url("../../../assets/Footer/footer1Bg.webp") center no-repeat;
    background-size: cover;
    position: relative;

    .Footer__1__filter {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgb(37, 72, 6);
      background: linear-gradient(
        180deg,
        rgba(37, 72, 6, 1) 0%,
        rgba(0, 10, 19, 1) 100%
      );
      opacity: 0.85;
    }

    .Footer__1__texts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: max(40px, 2vw);
      position: relative;
      z-index: 2;
      color: #fff;
    }

    .footer1__email {
      width: max(700px, 35vw);
      height: max(60px, 3.8vw);
      border-radius: max(10px, 0.5vw);
      border: 1px solid #ffffff;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;

      input {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border: none;
        outline: none;
        background-color: transparent;
        color: #fff;
        z-index: 2;
        padding-left: max(20px, 1vw);
      }

      button {
        position: absolute;
        z-index: 3;
        right: max(4px, 0.2vw);
        outline: none;
        border: none;
        height: max(50px, 3.3vw);
        width: 30%;
        border-radius: max(10px, 0.5vw);
        color: #fff;
        background-color: var(--main-color);
      }

      @media (max-width: 800px) {
        width: 95%;
      }
    }

    @media (max-width: 1300px) {
      aspect-ratio: auto;
      padding: max(40px, 2vw) 0;
    }
  }

  .Footer__2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: url("../../../assets/Footer/footer2Bg.webp") center no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: hidden;
    position: relative;

    .Footer__2__filter {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgb(21, 36, 7);
      background: linear-gradient(
        180deg,
        rgba(21, 36, 7, 1) 0%,
        rgba(43, 43, 43, 1) 100%
      );
      opacity: 0.92;
    }

    .Footer__2__shade {
      position: absolute;
      transform: translateX(30%);
      right: 0;
      top: max(40px, 2vw);
      height: 100%;
      content: url("../../../assets/Footer/footerShade.webp");
    }

    .Footer__2__inside {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      gap: max(80px, 4vw);
      width: 100%;
      padding: 4%;
    }

    .Footer__2__section__1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        gap: max(20px, 1vw);

        .whiteLogo {
          width: max(200px, 12vw);
          content: url("../../../assets/shared/darkLogo.svg");
        }

        p {
          color: #b4b3b3;
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: max(10px, 0.5vw);

        .appStore {
          width: max(140px, 7vw);
          content: url("../../../assets/Footer/AppStore.webp");
        }

        .googlePlay {
          width: max(140px, 7vw);
          content: url("../../../assets/Footer/googlePlay.webp");
        }

        @media (max-width: 300px) {
          flex-direction: column;
          gap: max(20px, 1vw);
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: max(20px, 1vw);

        .left {
          align-items: center;
          justify-content: center;
        }
      }
    }

    .Footer__2__section__2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      row-gap: max(40px, 2vw);

      .section__2__items {
        display: flex;
        flex-direction: column;
        gap: max(60px, 3vw);
        align-items: flex-start;

        p {
          font-size: max(20px, 1vw);
          font-family: "mo-medium";
          color: #fff;
          text-transform: uppercase;
          width: max(280px, 10vw);
          height: max(70px, 3.5vw);

          @media (max-width: 330px) {
            width: 95%;
          }
        }

        .section__2 {
          display: flex;
          flex-direction: column;
          gap: max(30px, 1.5vw);
          align-items: flex-start;

          a {
            color: #b5b5b4;
            text-decoration: none;
          }
        }
      }

      @media (max-width: 625px) {
        justify-content: center;
        align-items: center;
        text-align: center;

        .section__2__items {
          align-items: center;
          justify-content: center;

          .section__2 {
            text-align: center;
            align-items: center;
          }
        }
      }
    }

    .Footer__2__section__3 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        flex-direction: column;
        gap: max(90px, 4.5vw);

        .footer__2__left {
          display: flex;
          align-items: center;
          gap: max(12px, 0.6vw);

          .socialItem {
            display: flex;
            align-items: center;
            justify-content: center;
            width: max(42px, 2.1vw);
            height: max(42px, 2.1vw);
            aspect-ratio: 1/1;
            border-radius: max(6px, 0.3vw);
            border: 0.5px solid #fff;
            background-color: #3d3d3d;

            .socialItem__1 {
              width: (10px, 0.5vw);
              content: url("../../../assets/Footer/SocialNetwork/facebook.svg");
            }

            .socialItem__2 {
              width: (10px, 0.5vw);
              content: url("../../../assets/Footer/SocialNetwork/linkedin.svg");
            }

            .socialItem__3 {
              width: (10px, 0.5vw);
              content: url("../../../assets/Footer/SocialNetwork/youtube.svg");
            }

            .socialItem__4 {
              width: (10px, 0.5vw);
              content: url("../../../assets/Footer/SocialNetwork/twitter.svg");
            }

            .socialItem__5 {
              width: (10px, 0.5vw);
              content: url("../../../assets/Footer/SocialNetwork/dribbble.svg");
            }

            .socialItem__6 {
              width: (10px, 0.5vw);
              content: url("../../../assets/Footer/SocialNetwork/behance.svg");
            }
          }
        }

        p {
          color: #fff;
          font-size: max(12px, 0.6vw);
          font-family: "nn-regular";
        }

        @media (max-width: 380px) {
          
          .footer__2__left{
            flex-wrap: wrap;
            justify-content: center;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: max(80px, 4vw);
        align-items: flex-start;

        .top__right {
          display: flex;
          flex-direction: column;
          gap: max(18px, 0.9vw);

          p {
            font-size: max(26px, 1.3vw);
            color: #fff;
            font-family: "nn-medium";
          }

          .mosHumbInput {
            width: max(430px, 21.5vw);
            height: max(65px, 3.25vw);
            border-radius: max(10px, 0.5vw);
            background-color: #504f43;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;

            .emailIcon__footer {
              position: absolute;
              transform: translateX(50%);
              left: max(10px, 0.5vw);
              width: max(19px, 0.95vw);
              content: url("../../../assets/Footer/emailIcon.svg");
            }

            input {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: transparent;
              outline: none;
              border: none;
              padding-left: max(50px, 2.5vw);
              color: #fff;
              font-size: max(13px, 0.65vw);

              &::placeholder {
                color: #fff;
              }
            }

            .naPercjell {
              position: absolute;
              right: max(5px, 0.25vw);
              height: max(50px, 2.5vw);
              border-radius: 100vmax !important;
              outline: none;
              border: none;
              width: 30%;
              font-size: max(12px, 0.75vw);
              font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
              background-color: var(--main-color);
              color: #fff;
            }
          }
        }

        .bottom__right {
          display: flex;
          align-items: center;
          flex-direction: column-reverse;
          gap: max(20px, 1vw);
          position: relative;


          .bottom {
            display: flex;
            align-items: center;
            gap: max(20px, 1vw);
          }

          .emailIcon__footer {
            width: max(14px, 0.7vw);
            content: url("../../../assets/Footer/emailIcon.svg");
          }

          p {
            display: flex;
            align-items: center;
            gap: max(10px, 0.5vw);
            font-size: max(14px, 0.7vw);
            font-family: "nn-regular";
            color: #fff;
          }

          .darkMode {
            width: max(60px, 3vw);
            height: max(30px, 1.5vw);
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background-image: url("../../../assets/shared/moon.svg");
            background-position: center right max(5px, 0.35vw);
            background-repeat: no-repeat;
            background-size: max(10px, 0.7vw);
            overflow: hidden;
            border-color: #fff;
            background-color: #626460;
            position: relative;

            span {
              background-color: #404040;
              border: 1px solid #8a6dda;
              width: max(22px, 1.1vw) !important;
              height: max(22px, 1.1vw) !important;
              border-radius: 100%;
              position: relative;
              left: max(4px, 0.2vw);
              .bgImgDark {
                width: 60% !important;
                height: 60% !important;
                background-image: url("../../../assets/shared/sunny.svg");
              }
            }
          }

          .dropdown {
            background-color: var(--darker-color);
          }

          .single-language {
            .text {
              span {
                color: #fff !important;
              }
            }
          }
        }

        @media (max-width: 470px) {
          width: 100%;

          .top__right {
            width: 100%;
            align-items: center;
            justify-content: center;

            .mosHumbInput {
              width: 100% !important;
            }
          }

          .bottom__right {
            flex-direction: column-reverse;

            .bottom {
              flex-direction: column-reverse;
            }
          }
        }
      }

      @media (max-width: 820px) {
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);

        .left {
          gap: max(30px, 1.5vw);
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .right {
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: max(30px, 1.5vw);
        }
      }
    }
  }
}

body.dark-mode {
  .Footer {
    .Footer__2__section__3 {
      .darkMode {
        width: max(60px, 3vw) !important;
        height: max(30px, 1.5vw) !important;
        display: flex !important;
        align-items: center !important;
        justify-content: flex-end !important;
        background-image: url("../../../assets/shared/sunny.svg") !important;
        background-position: center left max(5px, 0.35vw) !important;
        background-repeat: no-repeat !important;
        background-size: max(15px, 0.85vw) !important;
        overflow: hidden !important;
        filter: brightness(100%) !important;
        position: relative;

        span {
          width: max(22px, 1.1vw) !important;
          height: max(22px, 1.1vw) !important;
          border: 2px solid #8a6dda !important;
          border-radius: 100% !important;
          background-color: #404040 !important;
          position: relative !important;
          right: max(4px, 0.2vw) !important;
          left: auto !important;

          .bgImgDark {
            width: 40% !important;
            height: 40% !important;
            background-image: url("../../../assets/shared/moon.svg") !important;
          }
        }
      }
    }
  }
}

.EksploroniTeGjitha {
  display: flex;
  flex-direction: column;
  gap: max(40px, 2vw);
  padding: 4% 8%;

  p {
    margin: 0;
  }

  .EksploroniTeGjitha__p {
    text-align: center;
  }

  .EksploroniTeGjitha__inside {
    display: flex;
    position: relative;

    .EksploroniTeGjitha__inside__left {
      position: relative;
      z-index: 2;
      left: max(50px, 2.5vw);
      display: flex;
      flex-direction: column;
      background-color: #f8f8f8;
      border-radius: max(24px, 1.2vw);
      margin-top: max(40px, 2vw);
      height: fit-content;
      width: max(350px, 20vw);

      .exploroniCard {
        width: 100%;
        height: max(70px, 3.5vw);
        border-radius: max(24px, 1.2vw);
        display: flex;
        align-items: center;
        padding-left: max(40px, 2vw);
        gap: max(30px, 1.5vw);
        cursor: pointer;

        .icon {
          width: max(25px, 1.25vw);
          content: url("../../../assets/EksploroniTeGjitha/icon1.svg");
        }

        &:hover {
          background-color: var(--main-color);
          color: #fff;

          .icon {
            filter: invert(100%);
          }
        }
      }

      .exploroniCardClick {
        background-color: var(--main-color);
        color: #fff;

        .icon {
          filter: invert(100%);
        }
      }
    }

    .EksploroniTeGjitha__inside__right {
      display: flex;
      flex-direction: column;
      gap: max(40px, 2vw);
      width: 70%;
      padding: max(50px, 2.5vw);
      height: fit-content;
      padding-left: max(140px, 7vw);
      border-radius: max(17px, 0.85vw);
      box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw) max(10px, 0.5vw);

      background-color: #fff;
      position: relative;
      right: 0;

      .Eksploroni__p {
        line-height: max(30px, 1.5vw) !important;

        .eksploroniBorder {
          width: max(100px, 5vw);
          height: 0;
          border-top: 2px dashed var(--main-color);
          margin-top: max(10px, 0.5vw);
        }
      }

      .eksploroniCards {
        display: flex;
        gap: max(50px, 2.5vw);
        flex-wrap: wrap;

        .cardRight {
          width: max(300px, 15vw);
          height: max(150px, 7.5vw);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: max(15px, 0.75vw);
          text-align: center;
          cursor: pointer;
          padding: max(10px, 0.5vw);

          .cardRight1 {
            width: max(40px, 2vw);
            content: url("../../../assets/EksploroniTeGjitha/right1.svg");
          }

          .cardRight2 {
            width: max(40px, 2vw);
            content: url("../../../assets/EksploroniTeGjitha/right2.svg");
          }

          .cardRight3 {
            width: max(40px, 2vw);
            content: url("../../../assets/EksploroniTeGjitha/right3.svg");
          }

          &:hover {
            box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw)
              max(10px, 0.5vw);
          }

          @media (max-width: 600px) {
            width: 100%;
          }
        }
      }
    }

    .eksploroniLink {
      font-family: "mo-semibold";
      font-size: max(14px, 0.85vw) !important;
      color: var(--main-color);
      padding-left: max(40px, 2vw);
      margin: max(20px, 1vw) 0;
      display: flex;
      align-items: center;
      gap: max(10px, 0.5vw);
      text-decoration: none;

      * {
        transition: all 250ms linear;
      }

      svg {
        width: max(20px, 1vw);
        height: max(20px, 1vw);
      }

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      gap: max(30px, 1.5vw);

      .EksploroniTeGjitha__inside__left {
        width: 100%;
        left: auto;
        flex-wrap: wrap;
      }

      .EksploroniTeGjitha__inside__right {
        width: 100%;
        padding-left: max(30px, 1.5vw);
      }
    }
  }
}

body.dark-mode {
  .EksploroniTeGjitha {
    .EksploroniTeGjitha__inside__left {
      background-color: #282828;
      border: 1px solid rgba(255, 255, 255, 0.2);

      .icon {
        filter: brightness(1000%);
        filter: invert(100%);
      }
    }

    .EksploroniTeGjitha__inside__right {
      background-color: #282828;
    }
  }
}

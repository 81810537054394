.LeftTestim {
  display: flex;
  flex-direction: column;
  gap: max(50px, 2.5vw);
  position: relative;
  z-index: 3;

  .LeftTestim__1 {
    display: flex;
    flex-direction: column;
    gap: max(10px, 0.5vw);
  }

  .with23 {
    font-size: max(17.8px, 1.15vw);
    font-family: "mo-medium";
  }

  p {
    margin: 0;
    color: #fff;
  }

  .LeftTestim__card {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: max(20px, 1vw);

    p {
      text-wrap: balance;
      width: max(240px, 12vw);
    }
  }

  .left1Icon1 {
    width: max(33px, 1.65vw);
    content: url("../../../assets/LeftTestim/left1.svg");
    @media (max-width: 450px) {
      scale: 0.6;
    }
  }

  .left1Icon2 {
    width: max(30px, 1.5vw);
    content: url("../../../assets/LeftTestim/left2.svg");
    @media (max-width: 450px) {
      scale: 0.6;
    }
  }

  .left1Icon3 {
    width: max(37px, 1.85vw);
    content: url("../../../assets/LeftTestim/left3.svg");
    @media (max-width: 450px) {
      scale: 0.6;
    }
  }

  .left1Icon4 {
    width: max(37px, 1.85vw);
    content: url("../../../assets/LeftTestim/left4.svg");

    @media (max-width: 450px) {
      scale: 0.6;
    }
  }

  @media (max-width: 450px) {
    justify-content: center;
    align-items: center;

    .LeftTestim__card {
      gap: max(10px, 0.5vw);
      width: 100%;
      padding-left: max(20px, 1vw);

      p {
        width: fit-content;
      }
    }
  }
}

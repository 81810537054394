.Btn {
  width: max(230px, 11.5vw);
  height: max(65px, 3.25vw);
  border-radius: max(24px, 1.2vw);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: max(15px, 0.75vw);
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
  background-color: var(--main-color);
  position: relative;
  cursor: pointer;
  overflow: hidden;
  text-transform: uppercase;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: max(24px, 1.2vw);
    position: absolute;
    z-index: 2;
    top: max(-8px, -0.4vw);
    right: max(-8px, -0.4vw);
    border: 1px solid #fff;
    transition: all 250ms ease-in-out;
  }

  &:hover {
    transition: all 250ms ease-in-out;

    &::before {
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 300px) {
    width: 95%;
    margin: 0 auto;
  }
}

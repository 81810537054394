.ProduktetENdryshme {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: max(20px, 1vw);
    padding: 8%;
    margin-top: max(100px, 5vw);

    p {
        margin: 0;
    }

    .ProduktetENdryshme__1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: max(50px, 2.5vw);



        .inputDiv {
            flex: 0.8;
            border: 1px solid rgba(90, 90, 90, 0.15);
            height: max(45px, 2.25vw);
            border-radius: max(10px, 0.5vw);
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;

            .inputIcon {
                width: max(15px, 0.75vw);
                content: url('../../../assets/ProduktetENdryshme/search.svg');
                position: absolute;
                top: 50%;
                left: max(15px, 0.75vw);
                transform: translateY(-50%);
                z-index: 2;
            }

            input {
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                padding-left: max(50px, 2.5vw);
                position: absolute;
            }

            button {
                width: max(150px, 7.5vw);
                height: 95%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: max(14px, 0.8vw);
                font-family: 'mo-medium';
                color: #fff;
                background-color: var(--main-color);
                position: absolute;
                right: 3px;
                border: none;
                outline: none;
                border-radius: max(12px, 0.6vw);

            }


        }

        .teFundit {
            display: flex;
            flex-direction: column;
            position: relative;
            z-index: 3;
            cursor: pointer;
            
            .teFundit__inside {
                width: max(180px, 9vw);
                height: max(45px, 2.25vw);
                border: 1px solid rgba(90, 90, 90, 0.15);
                border-radius: max(10px, 0.5vw);
                padding: max(10px, 0.5vw);
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: #fff;

                .iconTefundit {
                    height: max(18px, 0.9vw);
                    content: url('../../../assets/ProduktetENdryshme/asc.svg');
                }

                p {
                    font-size: max(14px, 0.85vw);
                    font-family: 'mo-medium';
                    color: #5D5D5D;
                }

                .arrowTefundit {
                    width: max(10px, 0.5vw);
                    content: url('../../../assets/ProduktetENdryshme/arrow.svg');
                }

                &:hover {
                    // background-color: rgba(90, 90, 90, 0.15);
                    box-shadow: inset 0px 0px max(10px, 0.5vw) rgba(90, 90, 90, 0.25);
                }
            }

            .teFundit__inside2 {
                position: absolute;
                top: 100%;
                display: none;

                .iconTefundit {
                    transform: rotate(180deg);
                }

                .arrowTefundit {
                    transform: rotate(180deg);
                }
            }

            &:hover {
                .teFundit__inside2 {
                    display: flex;
                }
            }


        }


        .produktet__filter {
            display: flex;
            align-items: center;
            gap: max(12px, 0.6vw);

            .filter1 {
                width: max(45px, 2.25vw);
                height: max(45px, 2.25vw);
                aspect-ratio: 1/1 !important;
                border: 1px solid rgba(90, 90, 90, 0.15);
                border-radius: max(10px, 0.5vw);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 250ms linear;

                .filter1Icon {
                    width: 50%;
                    content: url('../../../assets/ProduktetENdryshme/filter1.svg');
                }

                &:hover {
                    border: 1px solid rgba(90, 90, 90, 0.65);
                }
            }

            .filter2 {
                width: max(45px, 2.25vw);
                height: max(45px, 2.25vw);
                aspect-ratio: 1/1 !important;
                border: 1px solid rgba(90, 90, 90, 0.15);
                border-radius: max(10px, 0.5vw);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                transition: all 250ms linear;

                .filter2Icon {
                    width: 50%;
                    content: url('../../../assets/ProduktetENdryshme/filter2.svg');
                }

                &:hover {
                    border: 1px solid rgba(90, 90, 90, 0.65);
                }
            }
        }

        @media (max-width: 1000px) {
            gap: max(10px, 0.5vw);
        }

        @media (max-width: 800px) {
            .inputDiv {
                flex: 1;
            }
        }

        @media (max-width: 700px) {
            flex-direction: column;

            .inputDiv {
                width: 100%;
                height: max(45px, 2.25vw);
                flex: auto;
            }
        }
    }

    .ProduktetENdryshme__2 {
        // display: flex;

        .sc-fqkvVR {
            display: flex;
            gap: max(20px, 1vw);

            .rec-swipable{
                display: flex;

                .rec-carousel-item {
                    
                }
            }


        }

        .eyoHU{
            overflow: visible;
        }

        .dydPIA {
            overflow: hidden;

        }

        .rec-arrow-left {
            display: none;
        }

        .rec-arrow-right {
            display: none;
        }

        .sc-kAyceB {
            display: none;
        }
    }

    .ProduktetENdryshme__3 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: max(40px, 2vw) auto;
        gap: max(20px, 1vw);

        .ProduktetENdryshme__3__insider {
            width: max(250px, 12.5vw);
            height: max(65px, 3.25vw);
            border-radius: 100vmax !important;
            border: 1px solid rgba(90, 90, 90, 0.15);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 max(40px, 2vw);

            .numberProduktActive {
                width: max(47px, 2.35vw);
                height: max(38px, 1.9vw);
                border-radius: 100vmax !important;
                background-color: var(--main-color);
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
            }

            .numberProdukt{
                font-size: max(18px, 0.9vw);
                cursor: pointer;
            }
        }
        
        svg {
            height: max(30px, 1.5vw);
            width: max(30px, 1.5vw);
            cursor: pointer;
            color: rgba(0,0,0, 0.15);

            &:active {
                color: var(--main-color);
            }
        }
    }

    .rec-carousel-item {
        height: 100%;
        .sc-gEvEer {
            height: 100%;
            .ProduktetENdryshmeCard{
                height: 95%;
            }
        }
    }

    @media (max-width: 400px) {
        padding: 4%;
    }

    .carouselInLexoniTeRejatTona {
        a {
            text-decoration: none;
            color: #000;
        }
    }

}

body.dark-mode {
    .carouselInLexoniTeRejatTona {
        a {
            text-decoration: none;
            color: #fff !important;
        }
    }
}
.KontaktoniEkipinTone {
  position: relative;
  top: 4%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: max(20px, 1vw);
  position: relative;

  p {
    margin: 0;
  }

  @media (min-width: 1025px) {
    margin-top: 4%;
  }

  .KontaktoniEkipinTone__1 {
    width: 100%;
    aspect-ratio: 1/0.5;
    display: flex;
    align-items: center;
    justify-content: center;

    background-image: url("../../assets/KontaktoniEkipinTone/bgGreen.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .KontaktoniEkipinTone__1__filter {
      background-image: url("../../assets/KontaktoniEkipinTone/shadeBg.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      position: absolute;
      z-index: 4;
      top: 0;
      left: 0;
      bottom: 0;
    }

    .KontaktoniEkipinTone__2__filter {
      content: url("../../assets/KontaktoniEkipinTone/Wave.png");
      width: 100%;
      position: absolute;
      z-index: 2;
      bottom: -1px;
    }

    .KontaktoniEkipinTone__1__inside {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: max(20px, 1vw);
      width: 60%;
      margin-bottom: max(250px, 15vw);

      p {
        color: #fff;
      }

      .semibold60__kontaktoni {
        font-size: max(40px, 3vw);
        font-family: "mo-semibold";
      }
    }

    @media (max-width: 1000px) {
      aspect-ratio: 1/ 0.6;

      .KontaktoniEkipinTone__1__inside {
        width: 90%;
        margin-bottom: max(100px, 5vw);
      }
    }
  }

  .KontaktoniEkipinTone__2 {
    background-color: #fff;
    margin: 0 12%;
    position: relative;
    z-index: 4;
    border-radius: max(8px, 0.4vw);
    padding: max(80px, 4vw);
    box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw) max(10px, 0.5vw);
    margin-top: max(-25vw, -30vw);

    display: flex;
    flex-direction: column;
    gap: max(50px, 2.5vw);

    .twoInRow {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: max(40px, 2vw);
    }

    .labelAndInput {
      display: flex;
      flex-direction: column;
      gap: max(18px, 0.9vw);

      label {
        font-size: max(16px, 1vw);
        font-family: "mo-semibold";
      }

      input,
      select {
        width: 100%;
        height: max(75px, 3.75vw);
        border-radius: max(10px, 0.5vw);
        border: 1px solid #c3cad9;
        padding-left: max(30px, 1.5vw);
        padding-right: max(15px, 0.75vw);
        font-size: max(16px, 1vw);
        font-family: "mo-medium";
        outline: none;

        &::placeholder {
          color: #959ead;
        }
      }

      option {
        font-size: max(16px, 1vw);
        font-family: "mo-medium";
      }
    }

    .labelAndTextarea {
      display: flex;
      flex-direction: column;
      gap: max(18px, 0.9vw);

      label {
        font-size: max(16px, 1vw);
        font-family: "mo-medium";
        color: #5a7184;
      }

      textarea {
        width: 100%;
        height: max(180px, 9vw);
        border-radius: max(10px, 0.5vw);
        border: 1px solid #c3cad9;
        padding: max(20px, 1vw);
        font-size: max(16px, 1vw);
        font-family: "mo-medium";
        outline: none;

        &::placeholder {
          color: #959ead;
        }
      }
    }

    .pAtKontakti {
      margin-top: max(-20px, -1vw);
    }

    button {
      width: max(206px, 10.3vw);
      height: max(63px, 3.15vw);
      border-radius: max(8px, 0.4vw);
      background-color: var(--main-color);
      font-size: max(16px, 1vw);
      font-family: "mo-semibold";
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
    }

    @media (max-width: 900px) {
      padding: max(80px, 4vw) max(40px, 2vw);

      .twoInRow {
        display: flex;
        flex-direction: column;
      }
    }

    @media (max-width: 600px) {
      margin: 0 5%;

      margin-top: max(-20vw, -22vw);
      padding: max(80px, 4vw) max(10px, 0.5vw);
    }
  }

  .KontaktoniEkipinTone__3 {
    margin: 4% 12%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    gap: max(40px, 2vw);

    .KontaktoniEkipinTone__card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: max(20px, 1vw);
      justify-content: space-between;

      .bgCardIcon {
        width: max(64px, 3.2vw);
        height: max(64px, 3.2vw);
        aspect-ratio: 1/1 !important;
        border-radius: max(16px, 0.8vw);
        background-color: rgba(89, 193, 11, 0.19);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .insiderCardIcon {
          position: absolute;
          z-index: 99;
          width: 50%;
        }

        .insiderCardIcon1 {
          content: url("../../assets/KontaktoniEkipinTone/cardIcon1.svg");
        }

        .insiderCardIcon2 {
          content: url("../../assets/KontaktoniEkipinTone/cardIcon2.svg");
        }

        .insiderCardIcon3 {
          content: url("../../assets/KontaktoniEkipinTone/cardIcon3.svg");
        }
      }

      .makeSmallGap {
        margin-top: max(-10px, -0.5vw);
        width: 80%;
      }

      .kontaktoni16 {
        font-size: max(16px, 0.8vw);
        font-family: "mo-semibold";
        color: var(--main-color);
        cursor: pointer;
        border: 2px solid transparent;

        padding: max(10px, 0.5vw);
        border-radius: max(8px, 0.4vw);
        transition: all 250ms linear;

        &:hover {
          border: 2px solid var(--main-color);
        }
      }
    }

    @media (max-width: 900px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }
}

body.dark-mode {
    .KontaktoniEkipinTone {
        .KontaktoniEkipinTone__2__filter {
      content: url("../../assets/KontaktoniEkipinTone/darkWave.png");

        }

        .KontaktoniEkipinTone__2 {
            background-color: #282828;

            input, select, textarea {
            background-color: #282828;

            color: #fff;

            }
        }

        .labelAndTextarea {
            label {
                color: #fff;
            }
        }
    }
}

.PartneretTane {
  width: 100%;
  padding: 4% 8%;
  display: flex;
  flex-direction: column;
  gap: max(80px, 4vw);
  margin: max(40px, 2vw) 0;

  .sc-kAyceB {
    display: none;
  }

  .partnertTaneIcon {
    width: 60%;
    min-width: 100px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 250ms linear;
    &:hover {
      width: 62.5%;
    }
  }

  .partnertTaneIcon1 {
    content: url("../../../assets/PartneretTane/perShendet.webp");
  }

  .partnertTaneIcon2 {
    content: url("../../../assets/PartneretTane/gjithqka.webp");
  }

  .partnertTaneIcon3 {
    content: url("../../../assets/PartneretTane/blejFresh.webp");
  }

  .partnertTaneIcon4 {
    content: url("../../../assets/PartneretTane/perBeba.webp");
  }

  .partnertTaneIcon5 {
    content: url("../../../assets/PartneretTane/herby.webp");
  }

  .partnertTaneIcon6 {
    content: url("../../../assets/PartneretTane/perdite.webp");
  }

  .carouselInPartner {
    display: flex;
    margin-bottom: max(50px, 2.5vw);

    .sc-fqkvVR {
      display: flex;
      flex-direction: column;

      .rec-swipable {
        display: flex;
        align-items: center;
      }
    }

    .sc-aXZVg {
      position: absolute;
      left: 50%;
      bottom: max(50px, 2.5vw);
      width: max(22px, 1.1vw) !important;
      height: max(22px, 1.1vw) !important;
      min-width: auto !important;
      cursor: pointer;
      color: #fff;

      &:active,
      &:focus,
      &:hover {
        background-color: var(--main-color) !important;
      }
    }

    .iWchow {
      box-shadow: none !important;
    }

    .rec-arrow-left {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: max(14px, 0.7vw);
    }

    .rec-arrow-right {
      margin-left: max(40px, 2vw) !important;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: max(14px, 0.7vw);
    }
  }
}

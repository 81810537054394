.UdhetimiYne {
    display: flex;
    align-items: center;
    padding: 0 8%;
    gap: max(70px, 3.5vw);
    p {
        margin: 0;
    }



    .left {
        width: 50%;
        position: relative;
        display: flex;
   

        .UdhetimiYne__image {
            width: 80%;
            aspect-ratio: 1/0.54;
            border-radius: max(11px, 0.55vw);
            background-image: url('../../../assets/UdhetimiYne/bgUdhetimi.webp');
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            
            &::before {
                content: "";
                width: 200%;
                height: 130%;
                background-color: var(--light-color);
                opacity: 0.05;
                position: absolute;
                // top: max(-15px, -0.75vw);
                // left: max(-15px, -0.75vw);
                right: max(-25px, -1.25vw);
                bottom: max(-15px, -0.75vw);
                border-radius: max(38px, 1.8vw);
                z-index: -1;
            }
        }

        .UdhetimiYne__bg__3 {
            width: 70%;
            border-radius: max(11px, 0.55vw);
            content: url('../../../assets/UdhetimiYne/shadeUdhetimi.webp');
            position: absolute;
            left: 3%;
            bottom: -30%;
            z-index: -1;
            margin-left: -4000px !important;
            filter: drop-shadow(4000px 0 var(--main-color));
        }

        .UdhetimiYne__circle__1 {
            width: 3%;
            aspect-ratio: 1/1;
            border-radius: 100% !important;
            background-color: #2B2B2B;
            position: absolute;
            right: 5%;
            bottom: 20%;
        }

        .UdhetimiYne__circle__2 {
            width: 4%;
            aspect-ratio: 1/1;
            border-radius: 100% !important;
            background-color: var(--light-color);
            opacity: 0.5;
            position: absolute;
            left: 40%;
            bottom: -40%;
        }

        @media (max-width: 900px) {
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    .right{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);

        span {
            text-transform: uppercase;
            opacity: 0.5;
        }

        p {
            line-height: max(30px, 1.5vw);
        }

        .Btn {
            margin-top: max(30px, 1.5vw);
            &::before {
                display: none;
            }
        }

        @media (max-width: 900px) {
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    @media (min-width: 900px) {
        margin: max(150px, 7.5vw) 0;
    }

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column-reverse;
        gap: max(100px, 5vw);
        width: 100%;

        .left {
            margin-top: max(100px, 5vw);
        }
        
    }
}
.TestimiIUshqimeve {
    position: relative;
    top: 4%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: max(20px, 1vw);

    p {
        margin: 0;
    }

    @media (min-width: 1025px) {
        margin-top: 4%;
    }


    .TestimiIUshqimeve__1 {
        width: 100%;
        aspect-ratio: 1/0.2;
        background-image: url('../../assets/TestimePage/testimiUshqimeve.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;

        .TestimiIUshqimeve__filter {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }

        .TestimiIUshqimeve__1__insider {
            width: 100%;
            padding: 4%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            z-index: 3;

            .left {
                display: flex;
                flex-direction: column;
                gap: max(10px, 0.5vw);

                p {
                    color: #fff;
                }

                .make50font {
                    font-size: max(40px, 2.5vw);
                    font-family: 'mo-regular';
                    color: #fff;
                }
            }

            .right {
                width: max(400px, 30vw);
                height: max(150px, 7.5vw);
                border-radius: max(25px, 1.25vw);
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                @media (max-width: 400px) {
                    width: 95%;
                }
            }
        }

        @media (max-width: 700px) {
            aspect-ratio: 1/0.5;

            .TestimiIUshqimeve__1__insider {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                gap: max(20px, 1vw);
            }

        }
    }

    .TestimiIUshqimeve__2 {
        padding: 4%;
        padding-top: 0;
        display: flex;
        align-items: center;
        gap: max(5px, 0.25vw);

        svg {
            width: max(15px, 0.75vw);
            height: max(15px, 0.75vw);
        }

        p {
            cursor: pointer;
        }

        .changeColorP {
            color: var(--main-color);
        }
    }

    .TestimiIUshqimeve__bottom {
        display: flex;
        gap: max(20px, 1vw);
        justify-content: space-between;
        padding: 4%;
        padding-top: 0;

        .left {
            width: calc(80% - max(20px, 1vw));
            display: flex;
            flex-direction: column;
            gap: max(100px, 5vw);
            margin-bottom: max(100px, 5vw);

            .left__titleAndP {
                display: flex;
                flex-direction: column;
                gap: max(40px, 2vw);
                align-items: flex-start;

                .make50 {
                    font-size: max(40px, 2.5vw);
                    font-family: 'mo-bold';
                }

                .makeHeight {
                    line-height: max(30px, 1.5vw);
                    text-wrap: balance;
                    width: 70%;

                    @media (max-width: 700px) {
                        width: 100%;

                    }
                }
            }

            .left__1 {
                display: flex;
                flex-direction: column;
                gap: max(50px, 2.5vw);
                position: relative;

                .testimiCards {
                    width: 100%;
                    position: relative;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: max(20px, 1vw);
                    align-items: center;
                    padding-right: max(100px, 5vw);
                    margin: max(100px, 5vw) 0;

                    .testimiCard {
                        width: 90%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: max(20px, 1vw);
                        height: max(110px, 5.5vw);
                        border-radius: max(10px, 0.5vw);
                        box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw) max(10px, 0.5vw);
                        background-color: #fff;
                        position: relative;
                        z-index: 2;

                        .testimiCard__text {
                            padding-left: max(20px, 1vw);
                            font-size: max(14px, 0.8vw);
                            font-family: 'mo-medium';
                        }

                        .testimiCard__img {
                            height: 100%;
                            aspect-ratio: 1/1;
                            position: relative;
                            border-radius: max(10px, 0.5vw);
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: cover;
                        }
                    }

                    .circleAbsolute {
                        width: max(300px, 20vw);
                        height: max(300px, 20vw);
                        aspect-ratio: 1/1 !important;
                        border-radius: 100% !important;
                        border: 1px dashed var(--main-color);
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    @media (max-width: 700px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    @media (max-width: 450px) {
                        padding-right: max(10px, 0.5vw);

                        .testimiCard {
                            width: 100%;
                        }

                    }
                }

            }

        }

        .right {
            width: 20%;
            display: flex;
            flex-direction: column;
            gap: max(30px, 1.5vw);

            .right__1 {
                width: 100%;
                padding: max(20px, 1vw) max(30px, 1vw);
                display: flex;
                flex-direction: column;
                border-radius: max(10px, 0.5vw);
                background-color: var(--main-color);
                color: #fff;
                gap: max(20px, 1vw);

                .pWithSvg {
                    display: flex;
                    align-items: center;
                    gap: max(10px, 0.5vw);

                    svg {
                        color: #fff;
                    }
                }

                a {
                    height: max(50px, 2.5vw);
                    border-radius: 100vmax;
                    color: var(--main-color);
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                }
            }

            .faqeTeNgjashme {
                font-size: max(30px, 1.5vw);
                font-family: 'mo-semibold';
            }

            .right__2 {
                width: 100%;
                padding: max(20px, 1vw) max(30px, 1vw);
                display: flex;
                flex-direction: column;
                border-radius: max(10px, 0.5vw);
                gap: max(20px, 1vw);
                box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw) max(10px, 0.5vw);

                a {
                    text-decoration: none;
                    color: #3A3A3A;
                }
            }

            .right__3 {
                width: 100%;
                padding: max(20px, 1vw) max(30px, 1vw);
                display: flex;
                flex-direction: column;
                border-radius: max(10px, 0.5vw);
                gap: max(20px, 1vw);
                background-color: #F5F5F5;

                a {
                    width: max(160px, 8vw);
                    height: max(50px, 2.5vw);
                    border-radius: 100vmax !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    background-color: var(--main-color);
                    text-decoration: none;
                }
            }


            @media (max-width: 1000px) {
                display: flex;
                flex-direction: column;
            }
        }


        @media (max-width: 1150px) {
            flex-direction: column-reverse;
            gap: max(50px, 2.5vw);

            .left {
                width: 100%;
            }

            .right {
                width: 100%;
            }
        }
    }
}
.SherbimetTona {
  width: 80%;
  padding: 4%;
  margin: 0 auto;
  box-shadow: 0 0 max(20px, 1vw) rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: max(20px, 1vw);
  border-radius: max(8px, 0.4vw);
  position: relative;
  top: max(-20px, -7.5vw);
  background-color: #fff;
  z-index: 2;

  p {
    margin: 0;
  }

  .make80InSherbimet {
    width: 70%;
    @media (max-width: 900px) {
      width: 100%;
      text-align: center;
    }
  }

  span {
    color: #2b2b2b;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .Btn {
    align-self: flex-end;

    &::before {
      display: none;
    }
  }

  .sherbimetTonaCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: max(40px, 5vw);
    row-gap: max(50px, 2.5vw);

    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 550px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media (max-width: 900px) {
      top: max(-140px, -7vw);

    
  }
}

body.dark-mode {
    .SherbimetTona {
        background-color: #2b2b2b;
    }
}
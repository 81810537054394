.VecoritTona{
    width: 90%;
    padding: 8%;
    display: flex;
    flex-direction: column;
    gap: max(100px, 5vw);
    margin: max(100px, 5vw) auto;

    p {
        margin: 0;
        
    }

    .VecoritTonaP {
        width: 80%;
    }

    .VecoritTona__cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: max(40px, 5vw);
        row-gap: max(50px, 2.5vw);
        margin: 0 auto;
        
        @media (max-width: 1000px) {
          grid-template-columns: repeat(2, 1fr);
        }
    
        @media (max-width: 550px) {
          grid-template-columns: repeat(1, 1fr);
        }
    }
}
.ProduktetENdryshmeCard {
    display: flex;
    flex-direction: column;
    gap: max(30px, 1.5vw);
    border-radius: max(20px, 1vw);
    overflow: hidden;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw) max(10px, 0.5vw);
    margin: max(10px, 0.5vw) max(20px, 1vw);

    @media (max-width: 400px) {
        margin: max(10px, 0.5vw) 0;
    }

    p {
        margin: 0;
    }

    .ProduktetENdryshmeCard__top {
        width: 100%;
        aspect-ratio: 1/0.65;
        border-radius: max(20px, 1vw);
        background-image: url('../../../../assets/ProduktetENdryshmeCard/image1.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;

        .ProduktetENdryshmeCard__action {
            position: absolute;
            font-family: 'mo-medium';
            font-size: max(14px, 0.9vw) !important;
            width: max(120px, 6vw);
            height: max(48px, 2.4vw);
            border-radius: max(11px, 0.55vw);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--main-color);
            color: #fff;
            right: max(10px, 0.5vw);
            bottom: max(10px, 0.5vw);
        }
    }

    .ProduktetENdryshmeCard__bottom {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: max(30px, 1.5vw);
        padding: max(30px, 1.5vw);
        padding-top: 0;

        .ProduktetENdryshmeCard__bottom__1 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: start;

            p {
                &:first-child {
                    color: #E97908;
                }

                &:last-child {
                    color: #B7B7B7;
                }
            }
        }

        .testimi__P {
            width: 80%;
        }

        .ProduktetENdryshmeCard__bottom__2 {
            display: flex;
            align-items: center;
            gap: max(10px, 0.5vw);

            .iconCard2 {
                width: max(24px, 1.2vw);
                content: url('../../../../assets/ProduktetENdryshme/certifikimi.svg');
            }

            span {
                color: var(--main-color);
                padding-left: max(20px, 1vw);
            }
        }

        .ProduktetENdryshmeCard__bottom__3 {
            display: flex;
            align-items: center;
            gap: max(5px, 0.25vw);

            .goldStar {
                width: max(22px, 1.1vw);
                content: url('../../../../assets/ProduktetENdryshme/goldStar.svg');
            }

            .star {
                width: max(22px, 1.1vw);
                content: url('../../../../assets/ProduktetENdryshme/star.svg');
            }

            p {
                height: 100%;
                padding-top: 2px;
                padding-left: max(10px, 0.5vw);
            }
        }

    }
}
.BottomProduktet {
    display: flex;
    gap: max(30px, 1.5vw);
    text-align: center;
    justify-content: center;
    padding: max(40px, 2vw) max(80px, 4vw);

    dl, ol, ul {
        margin: 0;
        padding: 0;
    }
    
    .leftBottom__produktetMake20 {
        color: #3A3A3A;
    }

    .noResults {
        padding: max(100px, 5vw) 0;
        font-size: max(20px, 1vw);
        font-family: 'qs-medium';
        width: 100%;
    }

    .bottomEducation__left {
        width: 25%;
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);

        h2 {
            text-align: start;
            margin-bottom: max(15px, 0.75vw);
            font-size: max(20px, 1vw);
            font-family: 'mo-semibold';
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: max(20px, 1vw);

            li {
                display: flex;
                align-items: center;
                gap: max(10px, 0.5vw);

                .bottomIcon {
                    width: max(15px, 0.75vw);
                }

                label,
                p {
                    font-size: max(12.8px, 0.8vw);
                    font-family: 'mo-medium';
                    display: flex;
                    align-items: center;
                    text-align: start;
                    gap: max(10px, 0.5vw);
                    color: #3A3A3A;
                }

                .bottom__icon1 {
                    content: url("../../../assets/BottomProduktet/icon1.svg");
                }

                .bottom__icon2 {
                    content: url("../../../assets/BottomProduktet/icon2.svg");
                }

                .bottom__icon3 {
                    content: url("../../../assets/BottomProduktet/icon3.svg");
                }

                .bottom__icon4 {
                    content: url("../../../assets/BottomProduktet/icon4.svg");
                }

                .bottom__icon5 {
                    content: url("../../../assets/BottomProduktet/icon5.svg");
                }

                .bottom__icon6 {
                    content: url("../../../assets/BottomProduktet/icon6.svg");
                }

                .bottom__icon7 {
                    content: url("../../../assets/BottomProduktet/icon7.svg");
                }

                .bottom__icon8 {
                    content: url("../../../assets/BottomProduktet/icon8.svg");
                }

            }
        }

        .bottom__ul {
            width: 35%;
            min-width: max(150px, 7.5vw);
            position: relative;

            p {
                font-size: max(13px, 0.6vw);
                font-family: "qs-medium";
                border: 1px solid rgb(141, 141, 141);
                padding: max(10px, 0.5vw) max(15px, 0.75vw);
                border-radius: max(10px, 0.5vw);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: max(10px, 0.5vw);
            }

            input {
                width: 100%;
                border-right: none;
            }

            ul {
                display: none;
                width: 100%;
                background-color: #fff;
                height: auto;
                max-height: max(400px, 20vw);
                overflow: scroll;
                overflow-x: hidden;
                border: 1px solid #b5b5b5;
                position: absolute;
                border-radius: max(10px, 0.5vw);
                z-index: 999;

                li {
                    font-size: max(13px, 0.6vw);
                    font-family: "qs-medium";
                    padding: max(10px, 0.5vw);
                    padding-left: max(30px, 1.5vw);

                    &:hover {
                        background-color: var(--light-color);
                        cursor: pointer;
                    }
                }
            }

            &:hover {
                ul {
                    display: block;
                }

                p {
                    cursor: pointer;

                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .bottomEducation__left__4 {
            label {
                gap: max(5px, 0.25vw);
            }
        }

        .star {
            width: max(13px, 0.65vw);
            content: url("../../../assets/BottomProduktet/star.svg");
        }

        .grayStar {
            width: max(13px, 0.65vw);
            content: url("../../../assets/BottomProduktet/grayStar.svg");
        }

        @media (max-width: 450px) {
            flex-direction: column !important;
            flex-wrap: nowrap !important;
        }
    }

    .bottomEducation__right {
        width: 75%;
        display: flex;
        flex-direction: column;

        .bottomEducation__right__top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                flex-direction: column;
                gap: max(15px, 0.75vw);

                h1 {
                    text-align: start;
                    font-size: max(30px, 1.5vw);
                    font-family: "mont";
                }

                p {
                    text-align: start;
                    font-size: max(15px, 0.75vw);
                    font-family: "qs-medium";

                    @media (max-width: 720px) {
                        text-align: center;
                    }
                }


            }

            .right {
                display: flex;
                align-items: center;
                gap: max(15px, 0.75vw);

                p {
                    text-align: start;
                    font-size: max(12.8px, 0.8vw) !important;
                    font-family: 'mo-medium';
                }

                .pBorder {
                    border: 1px solid #C7C7C7;
                    padding: max(10px, 0.5vw) max(15px, 0.75vw);
                    border-radius: max(10px, 0.5vw);
                    display: flex;
                    align-items: center;
                    border-radius: 100vmax !important;
                }

                .inRightpBorder {
                    display: flex;
                    gap: max(15px, 0.75vw);
                    cursor: pointer;

                    p {
                        width: 100%;
                    }

                    .inRightpBorder {
                        border: none !important;
                    }

                    .line {
                        width: 2px;
                        height: max(18px, 0.9vw);
                        background-color: #C7C7C7;
                    }

                    .orange {
                        color: var(--main-color);
                    }

                    @media (max-width: 850px) {
                        display: none;
                    }
                }

                .inRightpBorder2 {

                    border-radius: max(10px, 0.5vw) !important;
                    color: #C7C7C7;
                }

                .insideP {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    border: none;

                    &:hover {
                        background-color: transparent !important;
                    }

                    span {
                        width: 100%;

                        &:hover {
                            background-color: var(--light-color);
                        }
                    }
                }
            }
        }

        .bottomEducation__right__mid {
            margin: max(50px, 2.5vw) 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: max(30px, 1.5vw);

            a {
                color: black;
                text-decoration: none;

                .ProduktetENdryshmeCard {
                    height: 100%;
                }

                .testimi__P {
                    text-align: start;
                }
            }

            @media (max-width: 1300px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 850px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .makeInLine {
            display: flex;
            flex-direction: column;

            .Page4Card {
                min-height: max(300px, 30vw);
            }
        }

        .perPageMain {
            position: relative;
            cursor: pointer;

            .perPage {
                display: none !important;
                position: absolute;
                z-index: 99;
                background-color: #fff;
                border-radius: max(5px, 0.25vw);
                top: 100%;
                left: 0;
                width: 100%;
                padding: max(10px, 0.5vw);

                border: 1px solid #C7C7C7;
                display: flex;
                flex-direction: column;
                gap: max(10px, 0.5vw);

                p {
                    &:hover {
                        background-color: var(--light-color);
                    }
                }
            }

            &:hover {
                .perPage {
                    display: flex !important;
                }
            }

            .perPageSort {
                display: flex;
                align-items: center;
                gap: max(20px, 1vw);
            }
        }

        .bottomEducation__right__bottom {
            display: flex;
            align-items: center;
            gap: max(40px, 2vw);

            margin: 0 auto;

            p {
                font-size: max(12px, 0.6vw);
                font-family: "qs-semibold";
                display: flex;
                align-items: center;
                gap: max(5px, 0.25vw);
                cursor: pointer;

                svg {
                    font-size: max(20px, 1vw);
                }
            }

            .bottom__numbers {
                display: flex;
                align-items: center;
                gap: max(30px, 1.5vw);
            }

            a {
                font-size: max(15px, 0.75vw);
                font-family: "qs-semibold";
                color: #000;
            }

            .activeBottom {
                width: max(39px, 2vw);
                height: max(39px, 2vw);
                border-radius: 100% !important;
                aspect-ratio: 1/1;
                color: #fff;
                background-color: var(--main-color);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            @media (max-width: 650px) {
                gap: max(20px, 1vw);
            }

            @media (max-width: 450px) {
                ul {
                    display: flex !important;
                    gap: max(10px, 0.5vw) !important;
                }
            }

            @media (max-width: 360px) {
                gap: max(5px, 0.25vw);
            }

            @media (max-width: 300px) {
                gap: max(5px, 0.25vw);
                display: flex;
                flex-direction: column;
            }
        }
    }

    .onlyThisUl {
        li {
            display: flex !important;
            width: 100% !important;

            input {
                width: max(20px, 1vw) !important;
                height: max(20px, 1vw) !important;
            }

            label {
                font-size: max(12.8px, 0.8vw);
                font-family: 'mo-medium';
                display: flex;
                align-items: center;
                text-align: start;
                gap: max(10px, 0.5vw);
                color: #3A3A3A;
            }
        }
    }

    .forMobile {
        display: none;
    }


    .perPageMain2{
        display: flex;
        gap: max(10px, 0.5vw);

        svg {
            &:nth-child(1) {
                width: max(17px, 0.85vw) !important;
                color: #C7C7C7;

            }
        }
    }


    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;

        .openFilter{
            width: 100%;
        }

        .bottomEducation__right {
            width: 100%;
        }

        .forMobile {
            display: flex;
            align-items: center;
            justify-content: center;

            p {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: max(25px, 1.25vw);
                font-family: "qs-bold";

                svg {
                    width: max(30px, 1.5vw);
                    height: max(30px, 1.5vw);
                    color: var(--light-color);
                }

                .rotateSvg {
                    transform: rotate(-90deg);
                }
            }
        }

        .mobileEducation {
            display: flex;
        }

        .mobileEducation.hide {
            display: none;
        }

        .mobileEducation.show {
            display: flex !important;
            padding-bottom: max(30px, 1.5vw);
        }

        .bottomEducation__left.openFilter {
            display: flex !important;
        }

        .bottomEducation__left.closeFilter {
            display: none !important;
        }

        .bottomEducation__left {
            display: flex !important;
            flex-direction: column !important;
            gap: 0 !important;

            div {
                border-top: 1px solid #000;
                align-items: center !important;

                &:last-child {
                    border-bottom: 1px solid #000;
                }
            }

            h2 {
                margin-bottom: 0 !important;
                padding: max(20px, 1vw) 0;
            }
        }

        .bottomEducation__left__1,
        .bottomEducation__left__2,
        .bottomEducation__left__3,
        .bottomEducation__left__4 {
            ul {
                display: none;
            }
        }
    }

    .bottomEducation__right__top {
        .left {
            display: flex !important;
            flex-direction: row !important;
            align-items: center;

            .exploreAndResults {
                display: flex;
                flex-direction: column;
                gap: max(10px, 0.5vw);

                h1 {
                    font-size: max(24px, 1.5vw);
                    font-family: 'mo-bold';
                    color: #3A3A3A;
                }
            }
        }

        .addName {
            display: flex;
            border: 1px solid #b5b5b5;

            input {
                outline: none;
                border: none;
                padding: 0 max(10px, 0.5vw);
                font-size: max(13px, 0.65vw);
                width: max(120px, 6vw);
            }

            .buttonIclearSearch {
                display: flex;

            }

            button {
                width: max(30px, 1.5vw);
                height: max(30px, 1.5vw);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: var(--main-color);
                color: #fff;
                border: none;

                svg {
                    width: max(15px, 0.75vw);
                    height: max(15px, 0.75vw);
                }
            }
        }
    }
}

body.dark-mode {
    .perPage {
        background-color: #262626 !important;
    }

    .bottomEducation__right__mid {
        a {
            color: #fff !important;
        }
    }

    .bottomEducation__left {
        div {
            border-color: #fff !important;

            ul {
                li {
                    input[type="checkbox"] {
                        background-color: red !important;
                    }

                    input[type="checkbox"]:checked {
                        background-color: #4caf50 !important;
                    }
                }
            }
        }
    }

    .bottom__numbers {
        a {
            color: #fff !important;
        }
    }

    .onlyThisUl {
        background-color: #262626 !important;
    }

    .bottomEducation__right__top {

        input {
            color: #fff !important;
        }
    }
}

@media (max-width: 1110px) {
    .BottomEducation {
        flex-direction: column !important;

        .bottomEducation__left {
            width: 100%;
            flex-wrap: wrap;
            flex-direction: row;
            gap: max(40px, 2vw);
            justify-content: space-between;
        }

        .bottomEducation__right {
            width: 100%;
        }
    }
}

@media (max-width: 740px) {
    .BottomEducation {
        .bottomEducation__right {
            .bottomEducation__right__top {
                display: flex;
                flex-direction: column;
                gap: max(20px, 1vw);
            }
        }
    }
}

@media (max-width: 600px) {
    .BottomEducation {
        .bottomEducation__right {
            .bottomEducation__right__top {

                .left {
                    flex-direction: column !important;
                    width: 100%;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    gap: max(20px, 1vw);
                }


            }
        }
    }

    .exploreAndResults {
        width: 100% !important;
        align-items: center;
    }

    .addName {
        width: 100% !important;

        input {
            width: 100% !important;
        }
    }


}

@media (max-width: 500px) {
    .BottomEducation {
        padding: max(40px, 2vw) max(20px, 1vw);
    }
}

body.dark-mode {
    .exploreAndResults{
        h1 {
            color: #fff !important;
        }
    }

    .leftBottom__produktetMake20 {
        color: #fff;
    }

    label {
        color: #fff !important;
    }
}
.Testimit {
  width: 100%;
  display: flex;
  gap: max(50px, 2.5vw);
  position: relative;
  z-index: 1;

  p {
    margin-bottom: 0;
  }

  .pInTestimit {
    width: max(580px, 29vw);

    @media (max-width: 700px) {
      width: 100%;
    }
  }

  .Testimit__left {
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: max(60px, 3vw);
    position: relative;
    align-items: flex-start;
    padding: 8%;
    justify-content: center;

    .testimetBgLeft {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-20%, -20%);
      width: 50%;
      aspect-ratio: 1/1;
      background-image: url("../../../assets/Testimit/bgLeft.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: max(220px, 11vw);
        font-family: "mo-semibold";
        color: var(--main-color);
        opacity: 0.1;
        text-transform: uppercase;
      }
    }

    .colorSpan {
      text-transform: uppercase;
      font-family: "mo-bold";
      color: var(--main-color);
    }

    .testimit__btn {
      display: flex;
      align-items: center;
      gap: max(40px, 2vw);

      .Btn {
        text-transform: uppercase;
        &:last-child {
          background-color: #2b2b2b;
        }
      }

      @media (max-width: 550px) {
        display: flex;
        flex-direction: column;
      }

      @media (max-width: 300px) {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .Testimit__right {
    width: 35%;
    aspect-ratio: 1/1.26;
    background-image: url("../../../assets/Testimit/rightIImage.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .circle {
      color: var(--main-color);
      width: max(50px, 2.5vw);
      height: max(50px, 2.5vw);
      border-radius: 100% !important;
      aspect-ratio: 1/1 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--main-color);
      cursor: pointer;

      svg {
        color: #fff;
        width: max(30px, 1.5vw);
        height: max(30px, 1.5vw);
      }
    }
  }

  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;

    .Testimit__left {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding-top: max(100px, 5vw);
    }

    .Testimit__right {
      width: 80%;
      margin: 0 auto;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.darkMode {
  width: max(20px, 2.3vw);
  height: max(20px, 2.3vw);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;
  border-radius: 100vmax;
  transition: 300ms all ease-in-out;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: max(15px, 1vw);
    height: max(15px, 1vw);

    .bgImgDark {
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/shared/moon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

body.dark-mode .darkMode {
  border: 1px solid #fff;
  transition: 300ms all ease-in-out;

  span {
    width: max(15px, 1vw);
    height: max(15px, 1vw);

    .bgImgDark {
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/shared/sunny.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.TrajnimCertifikim {
  display: flex;
  align-items: center;
  padding: 0 8%;
  padding-top: max(100px, 5vw);

  p {
    margin: 0;
  }

  .left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .TrajnimCertifikim__image {
      width: 40%;
      aspect-ratio: 1/1 !important;
      background-image: url("../../../assets/TrajnimCertifikim/circleImage.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: "";
        width: 125%;
        height: 125%;
        aspect-ratio: 1/1 !important;
        border-radius: 100% !important;
        background-color: var(--light-color);
        opacity: 0.1;
        position: absolute;
        z-index: -1;
      }
    }

    .TrajnimCertifikim__circle__1 {
      width: 4%;
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      background-color: var(--light-color);
      position: absolute;
      top: 0;
      right: 31%;
    }

    .TrajnimCertifikim__circle__2 {
      width: 5%;
      opacity: 0.8;
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      background-color: var(--light-color);
      filter: brightness(75%);
      position: absolute;
      left: 10%;
      bottom: 0;
    }

    .TrajnimCertifikim__circle__3 {
      width: 4%;
      opacity: 0.8;
      aspect-ratio: 1/1 !important;
      border-radius: 100% !important;
      background-color: var(--main-color);
      filter: brightness(75%);
      position: absolute;
      right: 20%;
      bottom: -25%;
    }

    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-top: max(100px, 5vw);
    }
  }

  .right {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: max(30px, 1.5vw);

    span {
      text-transform: uppercase;
      opacity: 0.5;
    }

    p {
      line-height: max(30px, 1.5vw);
    }

    .Btn {
      &::before {
        display: none;
      }
    }

    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: max(150px, 7.5vw) 0;
  }
}

.DropDownCard {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: max(10px, 0.5vw);
  cursor: pointer;

  .left {
    width: max(47px, 2.35vw);
    height: max(47px, 2.35vw);
    border-radius: 100% !important;
    aspect-ratio: 1/1 !important;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 250ms linear;

    .leftIcon {
      width: 50%;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: max(5px, 0.25vw);

    p {
      margin: 0;
    }
  }

  &:hover {
    .left {
      background: rgb(112, 112, 112);
      background: linear-gradient(
        180deg,
        rgba(112, 112, 112, 1) 0%,
        rgba(78, 148, 15, 1) 100%
      );
      transition: all 250ms linear;
    }

    .leftIcon {
      filter: brightness(10000%);
      position: relative;
      z-index: 99;
    }

    .right {
      p {
        &:first-child {
          color: var(--main-color);
        }
      }
    }
  }
}

body.dark-mode {
  .DropDownCard {
    .leftIcon {
      filter: brightness(1000%);
    }
  }
}

.TestimiProduktevePage {
    position: relative;
    top: 4%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: max(20px, 1vw);

    p {
        margin: 0;
    }

    @media (min-width: 1025px) {
        margin-top: 4%;
    }

    .TestimiProduktevePage__top {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        
        width: 100%;
        aspect-ratio: 1/0.2;
        background-image: url('../../assets/TestimiProduktevePage/testimiProduktevePageBg.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;
    
        .TopEducationFilter {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
    
    
        .insideTopEducation{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 3;
            gap: max(10px, 0.5vw);
    
            @media (max-width: 700px) {
                width: 100% !important;
            }
            .topEducation__line {
                width: max(108px, 5vw);
                height: max(11px, 0.55vw);
                border-radius: 100vmax !important;
                background-color: var(--main-color);
            }
    
            h1 {
                color: #fff;
                font-size: max(30px, 2.5vw);
                font-family: 'mo-semibold';
            }
    
            p {
                color: #fff;
                font-size: max(14.4, 0.9vw);
                font-family: 'mo-regular';
            }
    
            .topEducation__search {
                width: 40vw;
                height: max(60px, 3vw);
                border-radius: 100vmax;
                border: 1px solid #fff;
                display: flex;
                align-items: center;
                overflow: hidden;
                position: relative;
                margin-top: max(20px, 1vw);
    
                @media (max-width: 1200px) {
                    width: 600px;
                }
    
                @media (max-width: 700px) {
                    width: 90% !important;
                }
    
                .insideTopEducation__filter {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    // background: rgba(255, 255, 255, 0.25);
                    border-radius: 16px;
                    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    // backdrop-filter: blur(8.2px);
                    // -webkit-backdrop-filter: blur(8.2px);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    background-color: transparent;
                }
    
                .search {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: max(30px, 1.5vw);
                    width: max(20px, 1vw);
                    height: max(20px, 1vw);
                    content: url('../../assets/TestimiProduktevePage/search.svg');
                }
    
                input {
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    padding-left: max(60px, 3vw);
                    background-color: transparent;
                    border: none;
                    outline: none;
                    position: relative;

                    font-size: max(12.8px, 0.8vw) !important;
                    font-family: 'mo-regular' !important;
    
                    &::placeholder {
                        color: #fff;
                    }
                }
    
                .icon2Filter {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: max(30px, 1.5vw);
                    width: max(16px, 0.8vw);
                    height: max(16px, 0.8vw);
                    content: url('../../assets/TestimiProduktevePage/filter.svg');
                }
    
    
            }
    
            .filteredData {
                position: absolute;
                z-index: 99999;
                width: 100%;
                background-color: #fff;
                top: 98%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border: 1px solid #cfcfcf;
    
                a {
                    padding: max(10px, 0.5vw) 0;
                    width: 100%;
                    text-align: start;
                    h2 {
                        font-size: max(15px, 0.75vw);
                        color: #000;
                        padding: max(10px, 0.5vw) 0;
                        font-family: 'qs-medium';
                        padding-left: max(10px, 0.5vw);
                    }
    
                    &:hover {
                        background-color: var(--light-color);
                    }
                }
    
                
            }
        }

        @media (max-width: 1350px) {
                width: 100%;
                // aspect-ratio: 1/0.2;
                padding: max(100px, 5vw) 0;
            
        }
    
        @media (max-width: 600px) {
            height: 50vh;
        }
    }
}
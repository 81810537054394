.Login {
  position: relative;
  top: 4%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: max(20px, 1vw);
  padding: max(200px, 10vw) 0;

  p {
    margin: 0;
  }

  .Login__filter1 {
    position: absolute;
    z-index: 1;
    height: 100%;
    content: url("../../assets/Login/bgLogin.webp");
    right: 0;
    top: 0;
  }

  .Login__filter2 {
    position: absolute;
    z-index: 2;
    width: 100%;
    content: url("../../assets/Login/bgLogin2.webp");
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .Login__left {
    position: relative;
    z-index: 3;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .Login__left__inside {
      width: 90%;
      position: relative;

      .Login__left__filter {
        width: 100%;
        aspect-ratio: 1/1;
        min-width: 600px;
        background-image: url("../../assets/Login/bgLogin3.webp");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%);

        display: flex;
        align-items: center;
        justify-content: center;

        .filterText {
          position: absolute;
          font-family: "mo-semibold";
          font-size: max(200px, 25vw);
          color: #60ae86;
          opacity: 0.13;
        }
      }
    }
  }

  .Login__right {
    position: relative;
    z-index: 3;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .Login__right__inside {
      width: 80%;
      border-radius: max(10px, 0.5vw);
      position: relative;
      display: flex;
      flex-direction: column;
      gap: max(25px, 1.25vw);
      z-index: 3;
      background-color: #fff;
      padding: max(60px, 3vw) max(40px, 2vw);

      .Login__right__1 {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          flex-direction: column;
          gap: max(10px, 0.5vw);
          flex: 1;

          .reg__33 {
            font-size: max(26px, 1.65vw);
            font-family: "mo-medium";

            span {
              margin-left: max(30px, 1.5vw);
            }
          }

          .line {
            width: 70%;
            border-top: 1px solid #dbdbdb;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              border: 2px solid var(--main-color);
              transform: translateY(-50%);
              width: 50%;
            }
          }
        }

        .kyqu {
          //   width: max(120px, 6vw);
          //   height: max(41px, 2.05vw);
          //   border-radius: 100vmax !important;
          //   background-color: var(--main-color);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #2b2b2b;
          text-decoration: none;
        }

        @media (max-width: 400px) {
          flex-direction: column;
          gap: max(20px, 1vw);
          justify-content: center;
          align-items: center;

          .line {
            margin: 0 auto;
            width: 90%;
          }
        }
      }

      .mbajMendDheHarruat {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .withCheckbox {
          display: flex;
          flex-direction: row;
          gap: max(5px, 0.25vw);
          align-items: center;

          .changeColor {
            color: var(--main-color);
          }
        }

        a {
          text-decoration: none;
          color: var(--main-color);
        }

        @media (max-width: 400px) {
          flex-direction: column;
          gap: max(20px, 1vw);
          justify-content: center;
          align-items: center;

          .line {
            margin: 0 auto;
            width: 90%;
          }
        }
      }

      .loginBtn {
        width: 100%;
        border-radius: 100vmax !important;
        height: max(48px, 2.4vw);
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        color: #fff;
        background-color: var(--main-color);
      }

      .insidePneedToChangeAColor {
        a {
          color: var(--main-color);
          text-decoration: none;
        }
      }

      .makeGrayColor {
        font-size: max(11.2px, 0.65vw);
        font-family: "mo-regular";
        color: #2b2b2b;
      }

      .reg__inputAndLabel {
        display: flex;
        flex-direction: column;
        gap: max(15px, 0.75vw);

        .inputWithIcon {
          width: 100%;
          height: max(48px, 2.4vw);
          border-radius: 100vmax !important;
          position: relative;
          border: 1px solid rgba(148, 146, 146, 0.25);
          position: relative;
          overflow: hidden;

          .smallIconInsideInput {
            position: absolute;
            left: max(20px, 0.5vw);
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
          }

          .smallIconInsideInputEmail {
            width: max(18px, 0.9vw);
            content: url("../../assets/Regjistrohu/email.svg");
          }

          .smallIconInsideInputPassword {
            width: max(14px, 0.7vw);
            content: url("../../assets/Regjistrohu/password.svg");
          }

          input {
            position: absolute;
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            padding-left: max(50px, 2.5vw);
          }
        }
      }
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    padding: max(50px, 2.5vw) 0;

    .Login__left {
      width: 100%;
      position: relative;

      .Login__left__inside {
        position: relative;

        .Login__left__filter {
          position: relative;
          transform: translateY(0);
          min-width: auto;
        }
      }
    }

    .Login__right {
      width: 100%;
    }
  }

  @media (max-width: 650px) {
    .Login__left {
      .Login__left__inside {
        .Login__left__filter {
          min-width: auto;
          width: 100%;
          background-size: cover;
          background-color: var(--main-color);
        }
      }
    }

    .Login__right {
      width: 100%;
      .Login__right__inside {
        width: 90%;
        padding: max(40px, 2vw) max(20px, 1vw);
      }
    }
  }
}

body.dark-mode {
    .Login {

        .Login__right__inside {
            background-color: #2b2b2b;

            input {
                background-color: #404040;
                color: #fff;
            }
        }

        .kyqu{
            color: #fff !important;
        }
    }
}

.VecoritTonaCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: max(20px, 1vw);
  width: 80%;
  padding: max(10px, 0.5vw);
  border-radius: max(10px, 0.5vw);
  border: 1px solid transparent;
  transition: all 250ms linear;
  width: fit-content;

  p {
    margin: 0 !important;
  }

  .VecoritTonaCard__top {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: max(15px, 0.75vw);

    .left {
      width: max(40px, 2vw);
      margin-top: max(10px, 0.5vw);
    }

    p {
      width: 80%;
      justify-self: flex-start;
      align-self: flex-start;
    }
  }

  .VecoritTonaCard__border {
    width: 20%;
    height: 0;
    border-top: 2px dashed var(--main-color);
  }

  .VecoritTonaCard__p {
    text-wrap: balance;
    width: 100%;
  }

  &:hover {
    border: 1px solid var(--main-color);
    cursor: pointer;
  }
}

.MobileNavbar {
  width: max(300px, 15vw);
  height: 100vh;
  position: fixed;
  padding-top: max(50px, 2.5vw);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  overflow-x: hidden;

  .lineInMobile {
    width: 100%;
    height: 0;
    border-top: 1px solid #bdbdbd;
    position: absolute;
  }

  .mobileNavItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    .navItem {
      width: 100%;
      height: 100%;
      padding: max(20px, 1vw) 0;
      transition: all 150ms linear;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid #bdbdbd;

      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        padding-left: max(20px, 1vw);
        padding-right: max(20px, 1vw);
        svg {
          width: max(20px, 1vw);
          height: max(20px, 1vw);
        }

        .rotateSVG {
          transform: rotate(180deg);
        }
      }
    }

    .removePaddingFromNav {
      padding-bottom: 0;
      border-left: 3px solid var(--main-color);
      transition: all 250ms linear;
    }

    .mobileNavbar__with__dropdown {
      .dropDownMobile {
        display: none;
        flex-direction: column;
        margin-top: max(10px, 0.5vw);

        a {
          border-bottom: 1px solid #bdbdbd;
          display: flex;
          align-items: center;
          gap: max(20px, 1vw);
          height: max(50px, 2.5vw);

          &:first-child {
              border-top: 1px solid #bdbdbd;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .displayDropDown {
        display: flex;
      }
    }
  }

  .midNavbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: max(20px, 1vw);
    margin: max(20px, 1vw) auto;

    .darkMode {
      width: max(60px, 3vw);
      height: max(30px, 1.5vw);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-image: url("../../../assets/shared/moon.svg");
      background-position: center right max(5px, 0.35vw);
      background-repeat: no-repeat;
      background-size: 25%;
      overflow: hidden;
      border-color: #fff;
      border: 1px solid #2b2b2b;

      span {
        background-color: #404040;
        width: max(30px, 1.5vw);
        height: max(30px, 1.5vw);
        border-radius: 100%;
        .bgImgDark {
          width: 60% !important;
          height: 60% !important;
          background-image: url("../../../assets/shared/sunny.svg");
        }
      }
    }

    .dropdown {
      background-color: var(--darker-color);
    }

    .single-language {
      .text {
        span {
          color: #fff !important;
        }
      }
    }

    .select-language {
      border: 1px solid #2b2b2b;
      padding: max(10px, 0.5vw);
      border-radius: 100vmax !important;
      color: #2b2b2b;
      display: flex;
      align-items: center !important;

      .selectText {
        margin-bottom: 0;
      }
    }

    @media (max-width: 300px) {
      flex-direction: column;
    }
  }



  .mobileNav__bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    gap: max(20px, 1vw);
    margin-bottom: max(20px, 1vw);

    a {
      text-decoration: none;
    }

    .navbar__search {
      width: 85%;
      height: max(30px, 1.95vw);
      display: flex;
      align-items: center;
      border-bottom: 1.5px solid #2b2b2b;
      position: relative;

      svg {
        width: max(18px, 0.9vw);
        height: max(18px, 0.9vw);
        color: #2b2b2b;
        position: absolute;
        z-index: 2;
      }

      input {
        width: 100%;
        height: 100%;
        position: absolute;
        padding-left: max(30px, 1.5vw);
        outline: none;
        border: none;
      }
    }

    .kycu {
      width: max(120px, 5.55vw);
      height: max(40px, 1.95vw);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100vmax !important;
      border: 1px solid #2b2b2b;
      color: #2b2b2b;
    }

    .regjistrohu {
      width: max(120px, 5.55vw);
      height: max(40px, 1.95vw);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100vmax !important;
      background-color: var(--main-color);
      color: #fff;
    }

    .mobileNav__buttons {
      display: flex;
      flex-direction: row;
      gap: max(20px, 1vw);

      @media (max-width: 300px) {
        flex-direction: column;
      }
    }
  }

  .smallSpanInDropDown{
    font-size: max(11px, 0.55vw);
    font-family: 'mo-regular';
    color: #A1A1A1;
    text-align: end;
  }

  .moveToBottom {
    border-top: 1px solid #bdbdbd;
  }

  .makeBoldInMobileNav {
    color: var(--main-color) !important;
    font-weight: bolder;
  }

  .centerColor {
    display: flex;
    justify-content: center;
  }

  @media (max-width: 400px) {
    width: 100%;
  }
}

body.dark-mode {
  .MobileNavbar {
    background-color: #2b2b2b;

    .mobileNav__bottom {
      .navbar__search {
        background-color: #2b2b2b;
        border-color: #fff;

        svg {
          color: #fff;
        }

        input {
          background-color: #2b2b2b;
          color: #fff;
        }
      }

      .mobileNav__buttons {
        .kycu {
          color: #fff;
          border-color: #fff;
        }
      }
    }
  }

  .midNavbar {
    .select-language {
      border: 1px solid #fff;

      p,
      svg {
        color: #fff;
      }
    }

    .darkMode {
      width: max(60px, 3vw);
      height: max(30px, 1.5vw);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background-image: url("../../../assets/shared/sunny.svg");
      background-position: center left max(5px, 0.35vw);
      background-repeat: no-repeat;
      background-size: max(15px, 0.85vw);
      overflow: hidden;
      filter: brightness(100%);
      border: 1px solid #fff;

      span {
        width: max(30px, 1.5vw);
        height: max(30px, 1.5vw);
        border: 1px solid #fff;
        border-radius: 100%;
        background-color: #404040;

        .bgImgDark {
          width: 40% !important;
          height: 40% !important;
          background-image: url("../../../assets/shared/moon.svg");
          filter: brightness(10000%);
        }
      }
    }
  }
}

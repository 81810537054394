.main__Regjistrohu {
  .PartneretTane {
    margin: 0;
    padding: 2% 8%;
  }
}

.Regjistrohu {
  position: relative;
  top: 4%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: max(20px, 1vw);
  background: rgb(251, 251, 251);
  background: linear-gradient(
    90deg,
    rgba(251, 251, 251, 1) 0%,
    rgba(252, 252, 252, 1) 40%,
    rgba(252, 252, 252, 1) 45%,
    rgba(246, 247, 249, 1) 50%,
    rgba(246, 246, 246, 1) 55%,
    rgba(246, 247, 248, 1) 60%,
    rgba(246, 246, 247, 1) 100%
  );
  padding: max(100px, 5vw) 0;

  p {
    margin: 0;
  }

  @media (min-width: 1025px) {
    margin-top: 4%;
  }

  .Regjistrohu__left {
    width: 50%;
    display: flex;

    .Regjistrohu__left__inside {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 90%;
      border-radius: max(100px, 5vw);
      position: relative;
      left: max(-50px, -2.5vw);
      background-image: url("../../assets/Regjistrohu/regjistrohuBg.webp");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: max(50px, 2.5vw) 0;
      aspect-ratio: 1/0.8;

      .Regjistrohu__left__inside__filter {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(43, 43, 43, 0.6);
        border-radius: max(100px, 5vw);
      }
    }
  }

  .Regjistrohu__right {
    width: 50%;
    background-color: #fff;
    position: relative;
    border-top-left-radius: max(10px, 0.5vw);
    border-bottom-left-radius: max(10px, 0.5vw);

    .RegjistrohuIcon {
      position: absolute;
      z-index: 1;
      width: 65%;
      content: url("../../assets/Regjistrohu/bgRegjistrohuIcon.svg");
      right: 0;
      bottom: 0;
      transform: translateX(50%);
      opacity: 0.6;
    }

    .Regjistrohu__right__inside {
      position: relative;
      padding: max(40px, 2vw);
      display: flex;
      flex-direction: column;
      width: 80%;
      gap: max(25px, 1.25vw);
      z-index: 3;

      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    .Regjistrohu__right__1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        flex-direction: column;
        gap: max(10px, 0.5vw);
        flex: 1;

        .reg__33 {
          font-size: max(26px, 1.65vw);
          font-family: "mo-medium";
        }
        .line {
          width: 70%;
          border-top: 1px solid #dbdbdb;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            border: 2px solid var(--main-color);
            transform: translateY(-50%);
            width: 50%;
          }
        }
      }

      .kyqu {
        width: max(120px, 6vw);
        height: max(41px, 2.05vw);
        border-radius: 100vmax !important;
        background-color: var(--main-color);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        text-decoration: none;
      }

      @media (max-width: 350px) {
        flex-direction: column;
        gap: max(20px, 1vw);
        justify-content: center;
        align-items: center;

        .line {
          margin: 0 auto;
          width: 90%;
        }
      }
    }

    .Regjistrohu__right__2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: max(58px, 2.9vw);
      border-radius: max(24px, 1.2vw);
      box-shadow: rgba(149, 157, 165, 0.1) 0px max(4px, 0.2vw) max(10px, 0.5vw);
      padding: 0 max(30px, 1.5vw);
      gap: max(50px, 2.5vw);

      button {
        color: #8b8b8b;
        border: none;
        outline: none;
        background-color: transparent;
      }

      .reg__activeBtn {
        padding: max(20px, 1vw);
        height: max(40px, 2vw);
        border-radius: max(10px, 0.5vw);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: var(--main-color);
      }

      @media (max-width: 450px) {
        flex-direction: column;
        height: auto;
        padding: max(20px, 1vw) 0;
      }
    }

    .inRowInputOnly {
      display: flex;
      flex-direction: row;
      gap: max(30px, 1.5vw);

      @media (max-width: 450px) {
        flex-direction: column;
      }
    }

    .reg__inputAndLabel {
      display: flex;
      flex-direction: column;
      gap: max(15px, 0.75vw);

      .inputWithIcon {
        width: 100%;
        height: max(48px, 2.4vw);
        border-radius: 100vmax !important;
        position: relative;
        border: 1px solid rgba(148, 146, 146, 0.25);
        position: relative;
        overflow: hidden;

        .smallIconInsideInput {
          position: absolute;
          left: max(20px, 0.5vw);
          top: 50%;
          transform: translateY(-50%);
          z-index: 2;
        }

        .smallIconInsideInputUsername {
          width: max(15px, 0.75vw);
          content: url("../../assets/Regjistrohu/name.svg");
        }

        .smallIconInsideInputEmail {
          width: max(18px, 0.9vw);
          content: url("../../assets/Regjistrohu/email.svg");
        }

        .smallIconInsideInputNumri {
          width: max(15px, 0.75vw);
          content: url("../../assets/Regjistrohu/phone.svg");
        }

        .smallIconInsideInputVendi {
          width: max(18px, 0.9vw);
          content: url("../../assets/Regjistrohu/country.svg");
        }

        .smallIconInsideInputPassword {
          width: max(14px, 0.7vw);
          content: url("../../assets/Regjistrohu/password.svg");
        }

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
          padding-left: max(50px, 2.5vw);
        }
      }
    }

    .withCheckbox {
      display: flex;
      flex-direction: row;
      gap: max(5px, 0.25vw);
      align-items: center;

      .changeColor {
        color: var(--main-color);
      }
    }

    .regjistrohuBtn {
      width: 100%;
      border-radius: 100vmax !important;
      height: max(48px, 2.4vw);
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      color: #fff;
      background-color: var(--main-color);
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;

    .Regjistrohu__left {
      width: 100%;
      .Regjistrohu__left__inside {
        left: auto;
        width: 100%;
      }
    }

    .Regjistrohu__right {
      width: 100%;
    }
  }
}

body.dark-mode {
  .Regjistrohu {
    background: rgb(30, 30, 30);
    background: linear-gradient(
      90deg,
      rgba(30, 30, 30, 1) 0%,
      rgba(29, 29, 29, 1) 40%,
      rgba(29, 29, 29, 1) 45%,
      rgba(35, 34, 32, 1) 50%,
      rgba(35, 35, 35, 1) 55%,
      rgba(35, 34, 33, 1) 60%,
      rgba(35, 35, 34, 1) 100%
    );

    .Regjistrohu__right {
      background-color: #282828;

      input {
        background-color: #404040;
        color: #fff;
      }
    }
  }
}

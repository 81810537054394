.LexoniTeRejatTona {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: max(40px, 2vw);
    
    p {
        margin: 0;
        padding: 0 8%;
    }

    .Btn {
        align-self: center;

        &::before {
            display: none;
        }
    }

    .carouselInLexoniTeRejatTona {
       
        .rec-swipable {
            display: flex;
            gap: max(20px, 1vw);

            padding: max(10px, 0.5vw) 0;

            
            .rec-carousel-item {
                height: 100% !important;
                .sc-gEvEer {
                    height: 100% !important;
                    // width: 100% !important;

                    .LexoniTeRejatTonaCard {
                        height: 100% !important;
                    }
                }
            }

            @media (max-width: 686px) {
                gap: 0;
                
            }
        }

        .eyoHU {
            overflow: visible;
        }

        .sc-kAyceB {
            display: none;
        }

        .sc-aXZVg {
            position: relative;
            z-index: 4;

            &:active,
            &:focus,
            &:hover {
              background-color: var(--main-color) !important;
            }
        }

        .rec-arrow-left {
            position: absolute;
            left: max(20px, 1vw);
            width: max(30px, 1.5vw);
            height: max(30px, 1.5vw);
            min-width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: max(16px, 0.8vw);
            background-color: #B4B3B3;
            color: #fff;
        }

        .rec-arrow-right {
            position: absolute;
            right: max(20px, 1vw);
            width: max(30px, 1.5vw);
            height: max(30px, 1.5vw);
            min-width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: max(16px, 0.8vw);
            background-color: #B4B3B3;
            // background-color: var(--main-color) !important;
            color: #fff;
        }

        .dydPIA {
            overflow: visible;

        }
    }
}
.KeniNevojePerKonsulte {
  width: 100%;
  padding: 8%;
  display: flex;
  flex-direction: column;
  gap: max(30px, 1.5vw);

  p {
    margin: 0;
  }

  .KeniNevojePerKonsulte__content {
    display: flex;
    align-items: center;
    gap: max(50px, 2.5vw);

    .left {
      width: 70%;
      padding: 4%;
      display: flex;
      flex-direction: column;
      gap: max(50px, 2.5vw);
      border-radius: max(17px, 0.85vw);
      box-shadow: rgba(149, 157, 165, 0.1) 0px max(10px, 0.5vw) max(20px, 1vw);

      form {
        display: flex;
        flex-direction: column;
        gap: max(20px, 1vw);
        width: 100%;

        .inRow {
          display: flex;
          align-items: center;
          gap: max(50px, 2.5vw);
          width: 100%;

          @media (max-width: 700px) {
            flex-direction: column;
            gap: max(20px, 1vw);
          }
        }
      }

      .withLabel {
        display: flex;
        flex-direction: column;
        gap: max(15px, 0.75vw);
        width: 100%;

        .withInput {
          width: 100%;
          height: max(55px, 2.25vw);
          border: 1px solid #d8d8d8;
          border-radius: max(6px, 0.3vw);
          position: relative;

          input {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: max(6px, 0.3vw);
            padding-left: max(10px, 0.5vw);
            &:focus {
              outline: 1px solid var(--main-color);
              border-radius: max(6px, 0.3vw);
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          .iconInside {
            position: absolute;
            z-index: 2;
            right: max(20px, 1vw);
            top: 50%;
            transform: translateY(-50%);
            width: max(20px, 1vw);
          }

          .iconInside1 {
            content: url("../../../assets/KeniNevojePerKonsulte/user.svg");
          }

          .iconInside2 {
            content: url("../../../assets/KeniNevojePerKonsulte/build.svg");
          }

          .iconInside3 {
            content: url("../../../assets/KeniNevojePerKonsulte/email.svg");
          }

          .iconInside4 {
            content: url("../../../assets/KeniNevojePerKonsulte/phone.svg");
          }
        }
      }

      .withLabelTextarea {
        width: 100%;
        textarea {
          border: 1px solid #d8d8d8;
          border-radius: max(6px, 0.3vw);
          width: 100%;
          padding: max(10px, 0.5vw);

          &:focus {
            outline: 1px solid var(--main-color);
            border-radius: max(6px, 0.3vw);
          }
        }
      }

      button {
        width: max(180px, 9vw);
        height: max(48px, 2.4vw);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100vmax !important;
        color: #fff;
        background-color: var(--main-color);
        align-self: flex-end;
        border: none;
      }

      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    .right {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: max(60px, 3vw);

      .keniNevoje__card {
        display: flex;
        align-items: center;
        gap: max(30px, 2.5vw);
        position: relative;

        .keniNevoj__img {
          width: max(60px, 3.45vw);
          height: max(60px, 3.45vw);
          aspect-ratio: 1/1;
          border-radius: max(14px, 0.7vw);
          background-color: var(--main-color);
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          transition: all 250ms linear;

          &::before {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: max(14px, 0.7vw);
            position: absolute;
            z-index: -1;
            right: max(-15px, -0.75vw);
            top: max(-15px, -0.75vw);
            border: 1px solid #cfcdd6;
          }

          .keniNevoj__icon {
            width: 45%;
          }

          .keniNevoj__icon1 {
            content: url("../../../assets/KeniNevojePerKonsulte/icon1.svg");
          }
          .keniNevoj__icon2 {
            content: url("../../../assets/KeniNevojePerKonsulte/icon2.svg");
          }
          .keniNevoj__icon3 {
            content: url("../../../assets/KeniNevojePerKonsulte/icon3.svg");
          }

          &:hover {
            background-color: #fff;
            border: 1px solid var(--main-color);

            &::before {
              background-color: var(--main-color);
            }

            .keniNevoj__icon {
              position: relative;
              left: -1000px;
              filter: drop-shadow(1000px 0 var(--main-color));
            }
          }
        }

        .keniNevoj__texts {
          display: flex;
          flex-direction: column;
          gap: max(10px, 0.5vw);

          p {
            &:last-child {
              font-weight: 100;
            }
          }
        }
      }

      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }
}

body.dark-mode {
  .KeniNevojePerKonsulte {
    .withLabel {
      input,
      textarea {
        background-color: #282828 !important;
        color: #fff;
      }
    }
  }
}

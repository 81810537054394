.ProduktetDetails {
  position: relative;
  top: 4%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: max(20px, 1vw);

  p {
    margin: 0;
  }

  @media (min-width: 1025px) {
    margin-top: 4%;
  }

  .ProduktetDetails__inside {
    display: flex;
    flex-direction: column;
    gap: max(50px, 2.5vw);

    .ProduktetDetails__1 {
      display: flex;
      flex-direction: row;
      gap: max(40px, 2vw);
      padding: 0 4%;

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 50%;
        margin-top: max(10px, 0.5vw);
        gap: max(30px, 1.5vw);

        .makeInRowProduct {
          display: flex;
          align-items: center;
          gap: max(5px, 0.25vw);
        }

        .productIcon {
          width: max(47px, 2.35vw);
          height: max(47px, 2.35vw);
          aspect-ratio: 1/1 !important;
          border-radius: 100% !important;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        //   background-image: url('../../assets/ProduktetDetails/icon1.webp');
        }

        .prapaLink {
          width: max(150px, 7.5vw);
          height: max(45px, 2.25vw);
          border-radius: 100vmax !important;
          border: 1px solid #c7c7c7;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: max(10px, 0.5vw);
          color: #3a3a3a;
          text-decoration: none;

          svg {
            width: max(20px, 1vw);
            height: 100%;
            color: var(--main-color);
          }
        }

        .btnTestimiAndCertifikimi {
          display: flex;
          align-items: center;
          gap: max(10px, 0.5vw);

          .divBtn {
            border-radius: 100vmax !important;
            height: max(50px, 2.5vw);
            border: 1px solid #c7c7c7;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 max(20px, 1vw);
            gap: max(10px, 0.5vw);
          }

          .makeTheseSpanGreen {
            color: var(--main-color);
          }

          .divBtnIcon1 {
            height: max(23px, 1.2vw);
            content: url("../../assets/ProduktetDetails/tetimiIcon.svg");
          }

          .divBtnIcon2 {
            height: max(28px, 1.6vw);
            content: url("../../assets/ProduktetDetails/certifikimi.svg");
          }

          @media (max-width: 570px) {
            flex-direction: column;
          }
        }

        .vlersimi1 {
          display: flex;
          align-items: center;
          gap: max(20px, 1vw);

          .allStars {
            display: flex;
            align-items: center;
            gap: max(5px, 0.25vw);

            .yellowStar {
              width: max(22px, 1.1vw);
              content: url("../../assets/ProduktetDetails/star.svg");
            }
          }
        }

        .shkruajNjeKoment {
          border-radius: 100vmax !important;
          height: max(50px, 2.5vw);
          border: 1px solid #c7c7c7;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 max(20px, 1vw);
          gap: max(10px, 0.5vw);

          .grayStar {
            width: max(22px, 1.1vw);
            content: url("../../assets/ProduktetDetails/grayStar.svg");
          }
        }
      }

      .right {
        width: 50%;
        aspect-ratio: 1/1 !important;
        border-radius: max(20px, 1vw);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #52befe;

        .insideRight1 {
          height: 60%;
        }
      }

      @media (max-width: 1160px) {
        flex-direction: column;

        .left {
          width: 100%;
        }

        .right {
          width: 70%;
          margin: 0 auto;
        }
      }

      @media (max-width: 600px) {
        .left {
          width: 100%;
        }

        .right {
          width: 100%;
          overflow: hidden;
        }
      }
    }

    .ProduktetDetails__2 {
      display: flex;
      flex-direction: column;
      gap: max(30px, 1.5vw);
      padding: 4%;
      width: 60%;

      .monstera30Bold {
        font-size: max(30px, 1.5vw);
        font-family: "mo-bold";
      }

      p {
        line-height: max(30px, 1.5vw);
      }
    }

    .ProduktetDetails__3 {
      display: flex;
      flex-direction: column;
      gap: max(30px, 1.5vw);
      padding: 4%;
      padding-top: 0;
      width: 60%;

      .monstera30Bold {
        font-size: max(30px, 1.5vw);
        font-family: "mo-bold";
      }

      p {
        line-height: max(30px, 1.5vw);
      }

      .analizatKimike {
        display: flex;
        flex-direction: column;
        gap: max(10px, 0.5vw);
        width: 100%;

        .analizat {
          display: flex;
          gap: max(10px, 0.5vw);
          width: 100vw;
        }

        .analizatKimike__detail {
          height: max(73px, 3.65vw);
          border-radius: max(20px, 1vw);
          border: 1px solid #c7c7c7;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          justify-content: space-between;
          width: max(500px, 25vw);

          p {
            padding-left: max(30px, 1.5vw);
          }

          .btnInRight {
            height: max(63px, 3.15vw);
            width: 30%;
            border-radius: max(16px, 0.8vw);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--light-color);
            margin-right: max(5px, 0.25vw);
            color: #fff;
          }
        }

        .chemical {
          display: flex;
          gap: max(10px, 0.5vw);
        }

        .analizatInPercent {
          height: max(73px, 3.65vw);
          border-radius: max(20px, 1vw);
          border: 1px solid #c7c7c7;
          display: flex;
          flex-direction: column;
          gap: max(5px, 0.25vw);
          justify-content: center;
          align-items: center;
          padding: 0 max(10px, 0.5vw);

          .mgL {
            font-size: max(10px, 0.5vw);
            font-family: "mo-medium";
          }

          .with14Font {
            font-size: max(11.2px, 0.7vw);
            font-family: "mo-medium";
          }
        }

        @media (max-width: 750px) {
          .analizatInPercent {
          }
        }

        @media (max-width: 630px) {
          gap: max(30px, 1.5vw);
          .analizat {
            flex-direction: column;

            .analizatKimike__detail {
              width: 90%;
            }
          }
        }
      }
    }

    .ProduktetDetails__4 {
      display: flex;
      padding: 4%;
      padding-top: 0;
      width: 100%;

      .left {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);
      }

      .right {
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);
        align-self: flex-end;
        align-items: flex-end;

        .image1 {
          height: max(140px, 7vw);
          content: url("../../assets/ProduktetDetails/imageISO.svg");

          @media (max-width: 450px) {
            height: auto;
            width: 80%;
          }
        }

        .imageLogo {
          height: max(46px, 2.3vw);
          content: url("../../assets/ProduktetDetails/bigLogo.svg");

          @media (max-width: 450px) {
            height: auto;
            width: 60%;
          }
        }
      }

      .monstera30Bold {
        font-size: max(30px, 1.5vw);
        font-family: "mo-bold";
      }

      p {
        line-height: max(30px, 1.5vw);
      }
    }

    .ProduktetDetails__5 {
      display: flex;
      flex-direction: column;
      gap: max(30px, 1.5vw);
      padding: 4%;
      width: 60%;

      .monstera30Bold {
        font-size: max(30px, 1.5vw);
        font-family: "mo-bold";
      }

      .insideReview {
        width: 100%;
        height: max(110px, 5.5vw);
        border-radius: max(20px, 1vw);
        box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw)
          max(10px, 0.5vw);
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .reviewBtn {
          border-radius: 100vmax !important;
          width: max(160px, 8vw);
          height: max(50px, 2.5vw);
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #c7c7c7;
          color: #c7c7c7;
          margin-left: max(30px, 1.5vw);
        }
      }
    }

    .ProduktetDetails__6 {
      display: flex;
      flex-direction: column;
      gap: max(30px, 1.5vw);
      padding: 4%;
      width: 100%;

      .sc-fqkvVR {
        height: 100%;
      }

      .rec-carousel-item {
        height: 100%;
      }

      .sc-gEvEer {
        height: 100%;
      }

      .rec-swipable {
        height: 100%;
        .ProduktetENdryshmeCard {
          height: 95% !important;
          justify-content: space-between;
        }
      }

      .dydPIA {
        margin: 0;
      }

      .rec-arrow-right,
      .rec-arrow-left {
        display: none;
      }

      .sc-kAyceB {
        display: none;
      }
    }

    @media (max-width: 1160px) {
      .ProduktetDetails__2 {
        width: 100%;
      }

      .ProduktetDetails__3 {
        width: 100%;

        .analizatKimike {
          .analizatKimike__detail {
            width: 60%;
          }
        }
      }

      .ProduktetDetails__4 {
        width: 100%;
        flex-direction: column;

        .left {
          width: 100%;
        }

        .right {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
      }

      .ProduktetDetails__5 {
        width: 100%;
      }
    }
  }
}


body.dark-mode {
    .ProduktetDetails {
        .prapaLink {
            color: #fff !important;
        }
    }
}
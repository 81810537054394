.LexoniTeRejatTonaCard {
  width: 100%;
  border-radius: max(20px, 1vw);
  width: max(400px, 22.5vw);
  height: max(520px, 26vw);
  box-shadow: rgba(149, 157, 165, 0.2) 0px max(4px, 0.2vw) max(10px, 0.5vw);
  cursor: pointer;
  position: relative;

  * {
    transition: all 250ms linear !important;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .LexoniTeRejatTonaCard__img {
    width: 100%;
    // aspect-ratio: 1/0.75 !important;
    height: max(300px, 15vw);
    border-radius: max(20px, 1vw);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .LexoniTeRejatTonaCard__bottom {
    display: flex;
    flex-direction: column;
    padding: max(20px, 1vw);
    gap: max(20px, 1vw);

    .LexoniTeRejatTonaCard__1 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        font-family: "nn-medium";
        font-size: max(14px, 0.7vw);
        color: #fff;
        width: max(54px, 2.7vw);
        height: max(30px, 1.5vw);
        border-radius: max(7px, 0.35vw);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--main-color);
        outline: none;
        border: none;
      }
    }

    .LexoniTeRejatTonaCard__2 {
      font-family: "mo-semibold";
      font-size: max(18px, 0.9vw);
    }

    .LexoniTeRejatTonaCard__3 {
      display: flex;
      flex-direction: column;
      gap: max(20px, 1vw);

      p {
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }

//   &:hover {
//     height: auto !important;

//     .LexoniTeRejatTonaCard__img {
//       position: relative;
//       top: 0;
//       height: max(200px, 10vw);
//       aspect-ratio: 1/0.3;
//     }

//     // .LexoniTeRejatTonaCard__bottom {
//     //     position: absolute;
//     //     height: max-content;
//     //     background-color: #fff;
//     //     top: 50%;
//     // }

//     .LexoniTeRejatTonaCard__bottom {
//       position: relative;
//       top: 0;
//     }
//     .LexoniTeRejatTonaCard__3 {
//       p {
//         &:nth-child(2) {
//           display: flex;
//         }
//       }
//     }
//   }

    &:hover {

        .LexoniTeRejatTonaCard__bottom {
            position: absolute;
            bottom: 0;
            background-color: #fff;
            border-top-left-radius: max(20px, 1vw);
            border-top-right-radius: max(20px, 1vw);
            transition: all 250ms linear;
        }

        .LexoniTeRejatTonaCard__3 {
            p {
                &:nth-child(2) {
                transition: all 250ms linear;
                display: flex;
                }
            }
        }
    }


    @media (max-width: 1000px) {
        height: max-content;
        
    }

}

.MenyraMeEMire {
    display: flex;
    align-items: center;
    padding: 0 8%;
    gap: max(70px, 3.5vw);
    p {
        margin: 0;
    }



    .left {
        width: 60%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .MenyraMeEMire__image {
            width: 80%;
            aspect-ratio: 1/0.6;
            border-radius: max(11px, 0.55vw);
            background-image: url('../../../assets/MenyraMeEMire/bgMenyraMeEMire.webp');
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
        }

        .MenyraMeEMire__bg__3 {
            width: 50%;
            border-radius: max(11px, 0.55vw);
            content: url('../../../assets/MenyraMeEMire/shadeMenyraMeEMIre.webp');
            position: absolute;
            left: 3%;
            z-index: -1;
            bottom: -10%;
            
        }

        .MenyraMeEMire__circle__1 {
            width: 40%;
            aspect-ratio: 1/1;
            border-radius: 100% !important;
            position: absolute;
            right: 30%;
            top: 0;
            z-index: -1;
            border: 1px dashed #FF5300;

            &::before {
                content: "";
                width: 5%;
                aspect-ratio: 1/1;
                border-radius: 100% !important;
                position: absolute;
                background-color: #2B2B2B;
                right: 14%;
                top: 10%;
            }
        }

        

        @media (max-width: 900px) {
            width: 130%;
            align-items: center;
            justify-content: center;
            text-align: center;
            right: -15%;
        }
    }

    .right{
        width: 40%;
        display: flex;
        flex-direction: column;
        gap: max(30px, 1.5vw);

        span {
            text-transform: uppercase;
            opacity: 0.5;
        }

        p {
            line-height: max(30px, 1.5vw);
        }

        .Btn {
            margin-top: max(30px, 1.5vw);
            &::before {
                display: none;
            }
        }

        @media (max-width: 900px) {
            width: 100%;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }

    @media (min-width: 900px) {
        margin: max(150px, 7.5vw) 0;
    }

    @media (max-width: 900px) {
        display: flex;
        flex-direction: column-reverse;
        gap: max(100px, 5vw);
        width: 100%;

        .left {
            margin-top: max(100px, 5vw);
        }
        
    }
}
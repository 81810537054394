.SherbimetTonaCard {
  padding: max(20px, 1vw) max(40px, 2vw);
  width: 100%;
  border: 1px solid transparent;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  border-radius: max(11px, 0.55vw);
  cursor: pointer;
  overflow: hidden;

  .SherbimetTonaCard__icon {
    height: max(50px, 2.5vw);
    margin-bottom: max(10px, 0.5vw);
    position: relative;
    top: -300px;
    filter: drop-shadow(0 300px var(--main-color));
  }

  .makeGrayAtSherbimetTona {
    color: #2b2b2b;
    width: 100%;
  }

  p {
    margin: max(10px, 0.5vw) 0;
    &:nth-child(3) {
      text-wrap: balance;
      width: 90%;
    }
  }

  a {
    align-self: flex-end;
    color: #000;
    text-decoration: none;
    transition: all 250ms ease-in-out !important;
    color: transparent;
  }

  &:hover {
    border: 1px solid rgba(70, 70, 70, 0.1);
    transition: all 250ms ease-in-out !important;

    a {
      color: #000;
      display: block;
      transition: all 250ms ease-in-out !important;
    }
  }

  @media (max-width: 700px) {
    padding: max(20px, 1vw) max(20px, 1vw);

    p {
      margin: max(10px, 0.5vw) 0;
      &:nth-child(3) {
        text-wrap: balance;
        width: 100%;
      }
    }
  }
}

body.dark-mode {
  .SherbimetTonaCard {
    background-color: #2b2b2b;

    p {
      color: #fff;
    }

    &:hover {
      border: 1px solid rgba(70, 70, 70, 0.3);
      a {
        color: #fff;
      }
    }
  }
}
